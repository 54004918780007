import React, {FC, InputHTMLAttributes, useEffect, useRef, useState} from "react";
import styled from "@emotion/styled";
import {
  Box,
  FormHelperText,
  FormLabel,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import {IGButton} from "../index";
import {AttachFileRounded, CancelRounded, DeleteRounded} from "@mui/icons-material";
import {isArray} from "lodash";
import UploadIcon from "./UploadIcon";
import {getDocument, GlobalWorkerOptions} from "pdfjs-dist";

GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js";

export interface IGFileUploadProps {
  inputProps?: Omit<InputHTMLAttributes<HTMLInputElement>, "value" | "onChange" | "multiple">;
  buttonText?: string;
  value: File[] | FileList;
  text?: string;
  onChange: (files: FileList | any[], clear?: boolean) => void;
  multiple?: boolean;
  error?: boolean;
  touched?: boolean;
  helperText?: React.ReactNode;
  disabled?: boolean;
  draggable?: boolean;
  showFilePreview?: boolean;
  contentHeight?: string;
}

interface FileUploadWrapperProps {
  drag?: boolean;
  error?: boolean;
  disabled?: boolean;
  contentHeight?: string;
}

const FileUploadWrapper = styled(Box)<FileUploadWrapperProps>`
  position: relative;
  border-radius: 4px;
  background-color: ${({drag}) => (drag ? "#f5f5f5" : "rgba(25, 118, 210, 0.08)")};

  .content {
    height: ${({contentHeight}) => contentHeight || "150px"};
    padding: 1rem;
    border: 1px dashed;
    border-color: ${({error, disabled}) =>
      error ? "#d32f2f" : disabled ? "#e0e0e0" : "#1976d2"};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  input {
    //display: none;
    position: ${({drag}) => (drag ? "absolute" : "relative")};
    display: ${({drag}) => (drag ? "block" : "none")};
    inset: 0;
    opacity: 0;
  }
`;

const IGFileUpload: FC<IGFileUploadProps> = ({
  value,
  onChange,
  inputProps,
  multiple = false,
  text = "Import an Excel/CSV file",
  touched = false,
  error = false,
  helperText = "",
  disabled = false,
  draggable = true,
  buttonText = "Add",
  showFilePreview = false,
  contentHeight,
}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [dragActive, setDragActive] = useState(false);
  // We need to change this to reset input when we are removing selected file
  const [inputKey, setInputKey] = useState(Date.now());
  const {accept = "*/*"} = inputProps || ({} as InputHTMLAttributes<HTMLInputElement>);
  const [successfulPDFs, setSuccessfulPDFs] = useState<File[]>([]);
  const [passwordCallbackQueue, setPasswordCallbackQueue] = useState<Array<Record<string, File>>>(
    [],
  );
  const valueArray = value ? Array.from(value as FileList) : [];
  const selectedMimeTypes: string[] = [...valueArray.map(file => file.type.split("/")[0])];

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // file extension validation
  const validateFiles = (fileList: FileList, accept: string) => {
    const validFiles: File[] = [];
    for (const file of Array.from(fileList)) {
      const fileExtension =
        file.name
          .split(".")
          .pop()
          ?.toLowerCase() || "";
      const mimeType = file.type.split("/")[0]; // Extracts the type from the file's MIME type (e.g., 'image' from 'image/png')

      if (!selectedMimeTypes.length || selectedMimeTypes.every(type => type === mimeType)) {
        selectedMimeTypes.push(mimeType);
      } else {
        alert("Please upload files of the same type");
        continue;
      }

      // Check for MIME type pattern in the accept string (e.g., 'image/*')
      const isMimeTypePattern = accept.includes("/*");

      if (accept !== "*/*") {
        if (isMimeTypePattern) {
          const acceptedMimeType = accept.split("/")[0]; // Extracts the MIME type from the accept string (e.g., 'image' from 'image/*')
          if (acceptedMimeType !== mimeType) {
            alert("Extension Not Allowed: Please upload a valid file");
            return validFiles;
          }
        } else if (
          !accept
            .split(",")
            .map(ext => ext.trim().toLowerCase())
            .some(ext => ext.includes(fileExtension))
        ) {
          // If not a MIME type pattern, check for specific file extensions
          alert("Extension Not Allowed: Please upload a valid file");
          return validFiles;
        }
      }

      if (fileExtension === "pdf") {
        const pdfLoadingTask = getDocument(URL.createObjectURL(file));
        pdfLoadingTask.onPassword = () => {
          setPasswordCallbackQueue(prev => [...prev, {[file.name]: file}]);
        };
        pdfLoadingTask.promise.then(() => {
          setSuccessfulPDFs(prev => [...prev, file]);
        });
      } else {
        validFiles.push(file);
      }
    }
    return validFiles;
  };

  const handleChange = (fileList: any) => {
    if (!fileList || !fileList.length) {
      onChange([]);
      return;
    }

    const validFiles = validateFiles(fileList, accept);
    onChange(validFiles);
  };

  const draggableComponent = () => {
    return (
      <>
        <FileUploadWrapper
          drag={dragActive || undefined}
          error={touched ? error : undefined}
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDrag}
          onDrop={e => {
            e.preventDefault();
            e.stopPropagation();
            if (e.dataTransfer && e.dataTransfer.files) {
              setDragActive(false);
              handleChange(e.dataTransfer.files);
            }
          }}
          disabled={disabled}
          contentHeight={contentHeight}
        >
          <div className="content">
            <UploadIcon />
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1">Drag & Drop files or</Typography>
              <IGButton
                variant="text"
                onClick={() => {
                  hiddenFileInput.current?.click();
                }}
                disabled={disabled}
              >
                Browse
              </IGButton>
            </Box>
            <Typography variant="caption">Supported Formats: {accept}</Typography>
            <Typography variant="caption">{text}</Typography>
            {!multiple && valueArray.length > 0 && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={0.5}
                width="100%"
              >
                {showFilePreview && value[0] ? (
                  <img
                    src={URL.createObjectURL(value[0])}
                    alt={value[0]?.name}
                    style={{maxWidth: "50px", maxHeight: "50px"}}
                  />
                ) : (
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {value[0]?.name}
                  </Typography>
                )}
                <IconButton
                  size="small"
                  onClick={() => {
                    setInputKey(Date.now());
                    onChange([]);
                  }}
                >
                  <CancelRounded />
                </IconButton>
              </Box>
            )}
            {multiple && valueArray.length > 0 ? (
              <Box
                display="flex"
                alignItems="fex-start"
                flexDirection="column"
                justifyContent="flex-start"
                gap={0.5}
                width="100%"
              >
                <Typography variant="subtitle2">Selected</Typography>
                {Array.from(value as FileList).map((file, index) => (
                  <Box
                    key={file.name}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      borderRadius: "4px",
                      border: "1px solid #4CAF50",
                      justifyContent: "space-between",
                      padding: "0.5rem",
                      backgroundColor: "#FFF",
                    }}
                  >
                    <Typography
                      key={index}
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {file.name}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setInputKey(Date.now());
                        onChange(
                          Array.from(value as FileList).filter((file, i) => i !== index),
                          true,
                        );
                      }}
                      color="error"
                    >
                      <DeleteRounded />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            ) : null}
          </div>
          <input
            ref={hiddenFileInput}
            type="file"
            key={inputKey}
            multiple={multiple}
            onChange={event => {
              handleChange(event.target.files || new FileList());
              // event.target.value = "";
            }}
            accept={accept}
            disabled={disabled}
          />
          {touched && error && <FormHelperText error={true}>{helperText}</FormHelperText>}
        </FileUploadWrapper>
        {passwordCallbackQueue.length > 0 && (
          <Stack gap={1}>
            <Typography variant="body1" color="error">
              You have to unlock these files to upload it. Please follow the instructions below
            </Typography>
            <List>
              <ListItem>
                <Stack gap={1}>
                  <Typography variant="body2">
                    1. Open the document in a new tab by clicking on the "unlock" button
                  </Typography>
                  {passwordCallbackQueue.map(callBackObj => {
                    const fileName = Object.keys(callBackObj)[0];
                    return (
                      <Stack flexDirection="row" gap={1} alignItems="center">
                        <FormLabel>{fileName}</FormLabel>
                        <IGButton
                          variant="text"
                          onClick={() => {
                            // open the file in a new tab
                            window.open(URL.createObjectURL(callBackObj[fileName]), "_blank");
                            // remove the file from the queue
                            setPasswordCallbackQueue(prev =>
                              prev.filter(obj => Object.keys(obj)[0] !== fileName),
                            );
                          }}
                        >
                          Unlock
                        </IGButton>
                      </Stack>
                    );
                  })}
                </Stack>
              </ListItem>
              <ListItem>
                <Typography variant="body2">
                  2. Enter the password to unlock the document
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body2">
                  3. Print using the print option in the browser or press - Ctrl + P (windows)
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body2">4. Save the document as a PDF</Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body2">5. Upload the unlocked PDF</Typography>
              </ListItem>
            </List>
          </Stack>
        )}
      </>
    );
  };

  const standardComponent = () => {
    return (
      <Stack alignItems="start" gap={1}>
        <input
          ref={hiddenFileInput}
          hidden
          type="file"
          key={inputKey}
          multiple={multiple}
          onChange={event => {
            handleChange(event.target.files || new FileList());
          }}
          accept={accept}
          disabled={disabled}
        />
        {(multiple || (isArray(value) && !value.length)) && (
          <Stack gap={1}>
            <Typography fontWeight="bold">{text}</Typography>
            <IGButton
              variant="outlined"
              onClick={() => {
                hiddenFileInput.current?.click();
              }}
              disabled={disabled}
              startIcon={<AttachFileRounded />}
            >
              {buttonText}
            </IGButton>
          </Stack>
        )}
        {!multiple && valueArray.length > 0 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            gap={0.5}
            width="100%"
          >
            {showFilePreview ? (
              <img
                src={URL.createObjectURL(value[0])}
                alt={value[0]?.name}
                style={{maxWidth: "50px", maxHeight: "50px"}}
              />
            ) : (
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                color="primary"
              >
                {value[0]?.name}
              </Typography>
            )}
            <IconButton
              size="small"
              onClick={() => {
                setInputKey(Date.now());
                onChange([]);
              }}
              color="error"
            >
              <CancelRounded />
            </IconButton>
          </Box>
        )}
        {multiple && valueArray.length > 0 && (
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="flex-start"
            gap={0.5}
            width="100%"
          >
            {Array.from(value).map((file, index) => (
              <Box display="flex" width="100%">
                <Typography
                  key={index}
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "80%",
                  }}
                  color="primary"
                >
                  {file.name}
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => {
                    setInputKey(Date.now());
                    onChange(
                      Array.from(value).filter((file, i) => i !== index),
                      true,
                    );
                  }}
                  color="error"
                >
                  <CancelRounded />
                </IconButton>
              </Box>
            ))}
          </Box>
        )}
        {touched && error && <FormHelperText error={true}>{helperText}</FormHelperText>}
      </Stack>
    );
  };

  useEffect(() => {
    if (successfulPDFs.length > 0) {
      onChange([...(value ? Array.from(value as any) : []), ...successfulPDFs]);
      setSuccessfulPDFs([]);
    }
  }, [successfulPDFs, value]);

  return <>{draggable ? draggableComponent() : standardComponent()}</>;
};

export default IGFileUpload;
