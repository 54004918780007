import {
  BtScoreCardConfig,
  BtScoreCardCustomerDetails,
  BtScoreCardDetails,
  CrLandDocumentDetailDto,
  CrLandDocumentDetailResponse,
  LenderApprovalDetails,
  Rating,
  Rule,
} from "./../../models/activeLoans/activeLoans.model";
import {cmStatusEnum} from "../../enums/gold_loan/activeLoans/filter";
import {CRInterface} from "app/models/openCr";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {City} from "app/models/activeLoans/city";
import {GoldApiError} from "app/typings/api/goldApi.types";
import {activeLoansActions} from "./activeLoans.action";
import {
  getInitialState,
  paginationSliceCreator,
  PaginationState,
} from "app/store/factoryReducers";
import {
  CRLMJobDetail,
  LMUsers,
} from "../../infra/services/api/activeLoans/types";
import {getValueFromQueryParams} from "_metronic";

import {
  LoanMakerInputFieldsFlag,
  LMInsuranceDetails,
  LoanPrefilledMakerDetails,
  PostLoanDocsFlag,
  ActiveLoansType,
  ActiveLoansTabsList,
  RejectCrResponse,
  RejectCrConfigResponse,
  SendBackCrResponse,
  SendBackCrConfigResponse,
} from "app/models/activeLoans/activeLoans.model";

interface OpenModals {
  closeCRDialog: boolean;
  sendBackToSchedulingDialog: boolean;
  manageJob: boolean;
  remarkModal: boolean;
  blacklistModal: boolean;
  newLoanAmountUpdationModal: boolean;
  isMatchingDocsPopupOpen: boolean;
  rejectCr: boolean;
  askLmForAdditionalDetail: boolean;
  btScoreCard: boolean;
}

type sortType = "ASCENDING" | "DESCENDING" | null;

type LoadingErrorState =
  | "cities"
  | "customerRequests"
  | "assignCrToMe"
  | "removeCreditManager"
  | "closeCR"
  | "sendBackToScheduling"
  | "getLMJobDetails"
  | "getLMUsers"
  | "assignCurrentLM"
  | "removeCurrentLM"
  | "closeJob"
  | "blackListLoan"
  | "updateLoanAmount"
  | "getLoanTopUpAmount"
  | "getLoanMakerInputFieldsFlag"
  | "getLoanRenewalDetails"
  | "updateLoanRenewalDetails"
  | "skipPersonalLoan"
  | "cmJobLatestStatus"
  | "getLMInsuranceDetails"
  | "getInsuranceFlag"
  | "skipOfficeReview"
  | "getLandDocumentDetail"
  | "deleteLandDocumentDetail"
  | "getMatchingLandDocumentDetail"
  | "postLandDocumentDetail"
  | "markLandDocument"
  | "getLandStateList"
  | "getLandOwnerBorrowerList"
  | "markLandDocumentAsSame"
  | "getAgriExposureAmount"
  | "activateLoanMaker"
  | "getPrefilledLoanMakerDetails"
  | "getPostLoanDocsFlag"
  | "getLoanRequirementReason"
  | "getActiveLoansTabsList"
  | "rejectCr"
  | "rejectCrConfig"
  | "sendBackCr"
  | "sendBackCrConfig"
  | "getLoanCheckerStatus"
  | "sendApprovalMailToLender"
  | "requestLenderApproval"
  | "getBtScoreCardDetails"
  | "getBtScoreCardConfig"
  | "getBtScoreCardCustomerDetails";

export interface Filters {
  selectedCityId: number | null;
  fromDate: number | null;
  toDate: number | null;
  selectedCrId: string | null;
  cmStatus: string | null;
  fetchActive: boolean | null;
  sortCriteria: string | null;
  sortOrder: sortType;
}

const fromInitial = new Date(
  new Date().setDate(new Date().getDate() - 7),
).setHours(0, 0, 0);

const toInitial = new Date();
toInitial.setHours(24, 0, 0, 0);

export interface ActiveLoanState {
  filters: Filters;
  loading: Record<LoadingErrorState, boolean>;
  errors: Record<LoadingErrorState, GoldApiError | null>;
  citiesById: { [key: string]: City };
  customerRequestsById: { [key: string]: CRInterface };
  pagination: PaginationState;
  drawerOpen: boolean;
  selectedCr: CRInterface | null;
  lMJobDetails: CRLMJobDetail[];
  lMUsers: LMUsers[];
  openModals: OpenModals;
  landStateList: [];
  landOwnerBorrowerList: [];
  landDocumentDetail: CrLandDocumentDetailResponse | null;
  matchingLandDocumentDetail: CrLandDocumentDetailDto[];
  loanMakerInputFieldsFlag: LoanMakerInputFieldsFlag | null;
  cmJobLatestStatus: string | null;
  lmInsuranceDetails: LMInsuranceDetails | null;
  isInsuranceUpsellRestricted: boolean;
  loanMakerPrefilledDetails: LoanPrefilledMakerDetails | null;
  isEmploymentDetailsEditAllowed: boolean;
  isAgriDocFlowAllowed: boolean;
  isGuarantorDetailsRequired: boolean;
  overrideLandDocumentDuplicateCheck: boolean;
  totalAgriLoanAmountAllowed: number | null;
  postLoanDocsFlag: PostLoanDocsFlag | null;
  loanRequirementReason: string | null;
  activeTab: ActiveLoansType;
  activeLoansTabsList: ActiveLoansTabsList[];
  isOrnamentsCheckerActive: boolean;
  rejectCrDetail: RejectCrResponse | null;
  rejectCrConfig: RejectCrConfigResponse;
  sendBackCrDetail: SendBackCrResponse | null;
  sendBackCrConfig: SendBackCrConfigResponse;
  lenderApprovalDetails: LenderApprovalDetails | null;
  btScoreCardDetails: Record<
    number,
    {
      rating: Rating;
      entityRatingByEnum: Record<string, Rule>;
    }
  >;
  btScoreCardConfg: BtScoreCardConfig | null;
  selectedBtScoreCardMilestone: string;
  isBtScoreMilestoneCheckedByCm: boolean;
  btScoreCardCustomerDetails: Record<number, BtScoreCardCustomerDetails>;
  isLoanAmountSentToCustomerForBT: boolean;
}

const initialState: ActiveLoanState = {
  filters: {
    selectedCityId: Number(getValueFromQueryParams("selectedCityId") || 0),
    fromDate: Number(getValueFromQueryParams("fromDate") || fromInitial),
    toDate: Number(getValueFromQueryParams("toDate") || toInitial.getTime()),
    selectedCrId: getValueFromQueryParams("selectedCrId") || "",
    cmStatus: getValueFromQueryParams("cmStatus") || cmStatusEnum.ALL_CM_STATUS,
    fetchActive: Boolean(getValueFromQueryParams("fetchActive")),
    sortCriteria: getValueFromQueryParams("sortCriteria") || "JOB_TYPE",
    sortOrder:
      (getValueFromQueryParams("sortOrder") as sortType) || "ASCENDING",
  },
  loading: {
    rejectCr: false,
    rejectCrConfig: false,
    sendBackCr: false,
    sendBackCrConfig: false,
    cities: false,
    customerRequests: false,
    assignCrToMe: false,
    removeCreditManager: false,
    closeCR: false,
    sendBackToScheduling: false,
    getLMJobDetails: false,
    getLMUsers: false,
    assignCurrentLM: false,
    removeCurrentLM: false,
    closeJob: false,
    blackListLoan: false,
    updateLoanAmount: false,
    getLoanTopUpAmount: false,
    getLoanMakerInputFieldsFlag: false,
    getLoanRenewalDetails: false,
    updateLoanRenewalDetails: false,
    skipPersonalLoan: false,
    cmJobLatestStatus: false,
    getLMInsuranceDetails: false,
    getInsuranceFlag: false,
    skipOfficeReview: false,
    getLandDocumentDetail: false,
    deleteLandDocumentDetail: false,
    getMatchingLandDocumentDetail: false,
    postLandDocumentDetail: false,
    markLandDocument: false,
    getLandStateList: false,
    getLandOwnerBorrowerList: false,
    markLandDocumentAsSame: false,
    getAgriExposureAmount: false,
    activateLoanMaker: false,
    getPrefilledLoanMakerDetails: false,
    getPostLoanDocsFlag: false,
    getLoanRequirementReason: false,
    getActiveLoansTabsList: false,
    getLoanCheckerStatus: false,
    sendApprovalMailToLender: false,
    requestLenderApproval: false,
    getBtScoreCardDetails: false,
    getBtScoreCardConfig: false,
    getBtScoreCardCustomerDetails: false,
  },
  citiesById: {},
  customerRequestsById: {},
  matchingLandDocumentDetail: [],
  drawerOpen: false,
  selectedCr: null,
  lenderApprovalDetails: null,
  landStateList: [],
  landDocumentDetail: null,
  landOwnerBorrowerList: [],
  isGuarantorDetailsRequired: false,
  rejectCrDetail: null,
  rejectCrConfig: {
    rejectionCategoryConfigList: [],
    rejectionSubCategoryConfigList: [],
  },
  sendBackCrDetail: null,
  sendBackCrConfig: {
    sendBackCategoryConfigList: [],
    sendBackSubCategoryConfigList: [],
  },
  btScoreCardDetails: {},
  btScoreCardConfg: null,
  selectedBtScoreCardMilestone: "GUARANTOR_DETAIL",
  isBtScoreMilestoneCheckedByCm: false,
  btScoreCardCustomerDetails: {},
  errors: {
    rejectCr: null,
    rejectCrConfig: null,
    sendBackCr: null,
    sendBackCrConfig: null,
    cities: null,
    customerRequests: null,
    assignCrToMe: null,
    removeCreditManager: null,
    closeCR: null,
    sendBackToScheduling: null,
    getLMJobDetails: null,
    getLMUsers: null,
    assignCurrentLM: null,
    removeCurrentLM: null,
    closeJob: null,
    blackListLoan: null,
    updateLoanAmount: null,
    getLoanTopUpAmount: null,
    getLoanMakerInputFieldsFlag: null,
    getLoanRenewalDetails: null,
    updateLoanRenewalDetails: null,
    skipPersonalLoan: null,
    cmJobLatestStatus: null,
    getLMInsuranceDetails: null,
    getInsuranceFlag: null,
    skipOfficeReview: null,
    getLandDocumentDetail: null,
    deleteLandDocumentDetail: null,
    getMatchingLandDocumentDetail: null,
    postLandDocumentDetail: null,
    markLandDocument: null,
    getLandStateList: null,
    getLandOwnerBorrowerList: null,
    markLandDocumentAsSame: null,
    getAgriExposureAmount: null,
    activateLoanMaker: null,
    getPrefilledLoanMakerDetails: null,
    getPostLoanDocsFlag: null,
    getLoanRequirementReason: null,
    getActiveLoansTabsList: null,
    getLoanCheckerStatus: null,
    sendApprovalMailToLender: null,
    requestLenderApproval: null,
    getBtScoreCardDetails: null,
    getBtScoreCardConfig: null,
    getBtScoreCardCustomerDetails: null,
  },
  lMJobDetails: [],
  lMUsers: [],
  loanMakerInputFieldsFlag: null,
  totalAgriLoanAmountAllowed: null,
  cmJobLatestStatus: null,
  lmInsuranceDetails: null,
  loanMakerPrefilledDetails: null,
  isInsuranceUpsellRestricted: true,
  isEmploymentDetailsEditAllowed: true,
  overrideLandDocumentDuplicateCheck: false,
  isAgriDocFlowAllowed: false,
  postLoanDocsFlag: null,
  loanRequirementReason: null,
  activeTab: "CREDIT_QUEUE",
  activeLoansTabsList: [],
  isOrnamentsCheckerActive: false,
  isLoanAmountSentToCustomerForBT: false,
  openModals: {
    closeCRDialog: false,
    sendBackToSchedulingDialog: false,
    manageJob: false,
    remarkModal: false,
    blacklistModal: false,
    newLoanAmountUpdationModal: false,
    isMatchingDocsPopupOpen: false,
    rejectCr: false,
    askLmForAdditionalDetail: false,
    btScoreCard: false,
  },
  pagination: getInitialState("activeLoans"),
};
// >>>>>>> Active loans Initial State <<<<<<<<<

const activeLoansPaginationReducer = paginationSliceCreator("activeLoans");

export const activeLoansSlice = createSlice({
  name: "activeLoans",
  initialState,
  reducers: {
    resetActiveLoansState: (state) => {
      state.customerRequestsById = {};
    },
    resetFiltersState: (state) => {
      state.filters = {
        selectedCityId: 0,
        fromDate: fromInitial,
        toDate: toInitial.getTime(),
        selectedCrId: "",
        cmStatus: cmStatusEnum.ALL_CM_STATUS,
        fetchActive: true,
        sortCriteria: "JOB_TYPE",
        sortOrder: "ASCENDING",
      };
    },
    resetLoanDetailsState: (state, action: PayloadAction<number>) => {
      state.customerRequestsById[action.payload].loanTopUpAmountDetails = null;
      state.customerRequestsById[action.payload].loanRenewalDetails = null;
    },
    toggleDrawer: (state, action: PayloadAction<boolean>) => {
      state.drawerOpen = action.payload;
    },
    applyFilters: (state, action: PayloadAction<Filters>) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state.filters[key as keyof Filters] = value;
      }
      state.pagination.pageNo = 1;
    },
    clearFilters: (state, action: PayloadAction<Filters>) => {
      Object.keys(action.payload).forEach((filter: string) => {
        state.filters[filter as keyof Filters] = null;
      });
    },
    toggleModals: (
      state,
      action: PayloadAction<{ type: keyof OpenModals; value: boolean }>,
    ) => {
      const {type, value} = action.payload;
      state.openModals[type] = value;
    },
    setSelectedCR: (state, action: PayloadAction<CRInterface>) => {
      state.selectedCr = action.payload;
    },
    setActiveTab: (state, action: PayloadAction<ActiveLoansType>) => {
      state.activeTab = action.payload;
      state.filters = initialState.filters;
      state.pagination.pageNo = 1;
      state.customerRequestsById = {};
    },
    setLoading: (
      state,
      action: PayloadAction<{
        key: LoadingErrorState;
        value: boolean;
      }>,
    ) => {
      state.loading[action.payload.key] = action.payload.value;
    },
    setError: (
      state,
      action: PayloadAction<{
        key: LoadingErrorState;
        value: GoldApiError;
      }>,
    ) => {
      state.errors[action.payload.key] = action.payload.value;
    },
    setBtScoreCardDetails: (
      state,
      action: PayloadAction<{
        crId: number;
        btScoreCardDetails: BtScoreCardDetails;
      }>,
    ) => {
      const {crId, btScoreCardDetails} = action.payload;
      state.btScoreCardDetails[crId] = {
        ...btScoreCardDetails,
        entityRatingByEnum: btScoreCardDetails.entityRatingList.reduce(
          (acc, cur) => {
            acc[cur.entityEnum as string] = cur;
            return acc;
          },
          {} as Record<string, Rule>,
        ),
      };
    },
    setBtScoreCardConfig: (state, action) => {
      const {response} = action.payload;
      state.btScoreCardConfg = response;
    },
    setSelectedBtScoreCardMilestone: (state, action) => {
      state.selectedBtScoreCardMilestone = action.payload;
    },
    setIsBtScoreCardMilestoneCheckedByCm: (state, action) => {
      state.isBtScoreMilestoneCheckedByCm = action.payload;
    },
    setBtScoreCardCustomerDetails: (
      state,
      action: PayloadAction<{
        crId: number;
        btScoreCardCustomerDetails: BtScoreCardCustomerDetails;
      }>,
    ) => {
      const {crId, btScoreCardCustomerDetails} = action.payload;
      state.btScoreCardCustomerDetails[crId] = btScoreCardCustomerDetails;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(activeLoansActions.getCitiesList, (state) => {
        state.loading.cities = true;
      })
      .addCase(activeLoansActions.getCitiesListSuccess, (state, action) => {
        state.loading.cities = false;
        action.payload.cities.forEach((city: City) => {
          state.citiesById[city.id] = city;
        });
      })
      .addCase(activeLoansActions.getCitiesListFailure, (state, action) => {
        state.loading.cities = false;
        state.errors.cities = action.payload.error;
      })
      .addCase(activeLoansActions.getCrList, (state) => {
        state.loading.customerRequests = true;
      })
      .addCase(activeLoansActions.getCrListSuccess, (state, action) => {
        state.loading.customerRequests = false;
        state.customerRequestsById = {};
        action.payload.crList.forEach((cr: CRInterface) => {
          state.customerRequestsById[cr.id] = cr;
        });
      })
      .addCase(activeLoansActions.getCrListFailure, (state, action) => {
        state.loading.customerRequests = false;
        state.errors.customerRequests = action.payload.error;
      })
      .addCase(activeLoansActions.assignCrToMe, (state) => {
        state.loading.assignCrToMe = true;
      })
      .addCase(activeLoansActions.assignCrToMeSuccess, (state, action) => {
        state.loading.assignCrToMe = false;
        const {crId, userId} = action.payload.response;
        state.customerRequestsById[crId].creditManagerId = userId;
      })
      .addCase(activeLoansActions.assignCrToMeFailure, (state, action) => {
        state.loading.assignCrToMe = false;
        state.errors.assignCrToMe = action.payload.error;
      })
      .addCase(activeLoansActions.removeCreditManager, (state) => {
        state.loading.removeCreditManager = true;
      })
      .addCase(
        activeLoansActions.removeCreditManagerSuccess,
        (state, action) => {
          state.loading.removeCreditManager = false;
          const {crId} = action.payload;
          state.customerRequestsById[crId].creditManagerId = null;
          if (state.filters.cmStatus !== cmStatusEnum.ALL_CM_STATUS) {
            delete state.customerRequestsById[crId];
          }
        },
      )
      .addCase(
        activeLoansActions.removeCreditManagerFailure,
        (state, action) => {
          state.loading.removeCreditManager = false;
          state.errors.removeCreditManager = action.payload.error;
        },
      )
      .addCase(activeLoansActions.closeCR, (state) => {
        state.loading.closeCR = true;
      })
      .addCase(activeLoansActions.closeCRSuccess, (state, action) => {
        state.loading.closeCR = false;
        state.openModals.closeCRDialog = false;
        const {crId} = action.payload.response;
        delete state.customerRequestsById[crId];
      })
      .addCase(activeLoansActions.closeCRFailure, (state, action) => {
        state.loading.closeCR = false;
        state.errors.closeCR = action.payload.error;
      })
      .addCase(activeLoansActions.sendBackToScheduling, (state) => {
        state.loading.sendBackToScheduling = true;
      })
      .addCase(
        activeLoansActions.sendBackToSchedulingSuccess,
        (state, action) => {
          state.loading.sendBackToScheduling = false;
          const {crId} = action.payload;
          delete state.customerRequestsById[crId];
          state.openModals.sendBackToSchedulingDialog = false;
        },
      )
      .addCase(
        activeLoansActions.sendBackToSchedulingFailure,
        (state, action) => {
          state.loading.sendBackToScheduling = false;
          state.errors.sendBackToScheduling = action.payload.error;
        },
      )
      .addCase(activeLoansActions.getLMJobDetails, (state) => {
        state.loading.getLMJobDetails = true;
      })
      .addCase(activeLoansActions.getLMJobDetailsSuccess, (state, action) => {
        state.loading.getLMJobDetails = false;
        state.lMJobDetails = action.payload.response;
      })
      .addCase(activeLoansActions.getLMJobDetailsFailure, (state, action) => {
        state.loading.getLMJobDetails = false;
        state.errors.getLMJobDetails = action.payload.error;
      })
      .addCase(activeLoansActions.getLMUsers, (state) => {
        state.loading.getLMUsers = true;
      })
      .addCase(activeLoansActions.getLMUsersSuccess, (state, action) => {
        state.loading.getLMUsers = false;
        state.lMUsers = action.payload.lmUserList;
      })
      .addCase(activeLoansActions.getLMUsersFailure, (state, action) => {
        state.loading.getLMUsers = false;
        state.errors.getLMUsers = action.payload.error;
      })
      .addCase(activeLoansActions.assignCurrentLM, (state) => {
        state.loading.assignCurrentLM = true;
      })
      .addCase(activeLoansActions.assignCurrentLMSuccess, (state, action) => {
        state.loading.assignCurrentLM = false;
        const {agentId} = action.payload.response;
        const agent = state.lMUsers.find((a) => a.id === agentId);
        if (agent && state.lMJobDetails.length > 0) {
          state.lMJobDetails[0].agentId = agentId;
          state.lMJobDetails[0].agentName = agent.name;
        }
      })
      .addCase(activeLoansActions.assignCurrentLMFailure, (state, action) => {
        state.loading.assignCurrentLM = false;
        state.errors.assignCurrentLM = action.payload.error;
      })
      .addCase(activeLoansActions.removeCurrentLM, (state) => {
        state.loading.removeCurrentLM = true;
      })
      .addCase(activeLoansActions.removeCurrentLMSuccess, (state, action) => {
        state.loading.removeCurrentLM = false;
        state.openModals.remarkModal = false;
        state.lMJobDetails[0].agentId = null;
        state.lMJobDetails[0].agentName = null;
      })
      .addCase(activeLoansActions.removeCurrentLMFailure, (state, action) => {
        state.loading.removeCurrentLM = false;
        state.errors.removeCurrentLM = action.payload.error;
      })
      .addCase(activeLoansActions.closeLMJob, (state) => {
        state.loading.closeJob = true;
      })
      .addCase(activeLoansActions.closeLMJobSuccess, (state, action) => {
        state.loading.closeJob = false;
        state.openModals.remarkModal = false;
        state.openModals.manageJob = false;
        state.lMJobDetails = [];
      })
      .addCase(activeLoansActions.closeLMJobFailure, (state, action) => {
        state.loading.closeJob = false;
        state.errors.closeJob = action.payload.error;
      })
      .addCase(activeLoansActions.blackListLoan, (state) => {
        state.loading.blackListLoan = true;
        state.errors.blackListLoan = null;
      })
      .addCase(activeLoansActions.blackListLoanSuccess, (state, action) => {
        state.loading.blackListLoan = false;
      })
      .addCase(activeLoansActions.blackListLoanFailure, (state, action) => {
        state.loading.blackListLoan = false;
        state.errors.blackListLoan = action.payload.error;
      })

      .addCase(activeLoansActions.updateLoanAmount, (state) => {
        state.loading.updateLoanAmount = true;
        state.errors.updateLoanAmount = initialState.errors.updateLoanAmount;
      })
      .addCase(activeLoansActions.updateLoanAmountSuccess, (state) => {
        state.loading.updateLoanAmount = false;
      })
      .addCase(activeLoansActions.updateLoanAmountFailure, (state, action) => {
        state.loading.updateLoanAmount = false;
        state.errors.updateLoanAmount = action.payload.error;
      })
      .addCase(activeLoansActions.getLoanTopUpAmount, (state) => {
        state.loading.getLoanTopUpAmount = true;
        state.errors.getLoanTopUpAmount =
          initialState.errors.getLoanTopUpAmount;
      })
      .addCase(
        activeLoansActions.getLoanTopUpAmountSuccess,
        (state, action) => {
          state.loading.getLoanTopUpAmount = false;
          const {crId} = action.payload;
          state.customerRequestsById[crId] = {
            ...(state.customerRequestsById[crId] || {}),
            loanTopUpAmountDetails: action.payload.response,
          };
          state.errors.getLoanTopUpAmount = null;
        },
      )
      .addCase(
        activeLoansActions.getLoanTopUpAmountFailure,
        (state, action) => {
          state.loading.getLoanTopUpAmount = false;
          state.errors.getLoanTopUpAmount = action.payload.error;
        },
      )
      .addCase(activeLoansActions.getMakerFieldsFlag, (state) => {
        state.loading.getLoanMakerInputFieldsFlag = true;
        state.errors.getLoanMakerInputFieldsFlag =
          initialState.errors.getLoanMakerInputFieldsFlag;
      })
      .addCase(
        activeLoansActions.getMakerFieldsFlagSuccess,
        (state, action) => {
          state.loading.getLoanMakerInputFieldsFlag = false;
          state.loanMakerInputFieldsFlag = action.payload.response;
        },
      )
      .addCase(
        activeLoansActions.getMakerFieldsFlagFailure,
        (state, action) => {
          state.loading.getLoanMakerInputFieldsFlag = false;
          state.errors.getLoanMakerInputFieldsFlag = action.payload.error;
        },
      )
      .addCase(activeLoansActions.getLoanRenewalDetails, (state) => {
        state.loading.getLoanRenewalDetails = true;
        state.errors.getLoanRenewalDetails =
          initialState.errors.getLoanRenewalDetails;
      })
      .addCase(
        activeLoansActions.getLoanRenewalDetailsSuccess,
        (state, action) => {
          state.loading.getLoanRenewalDetails = false;
          const {crId} = action.payload;

          state.customerRequestsById[crId] = {
            ...(state.customerRequestsById[crId] || {}),
            loanRenewalDetails: action.payload.response,
          };
          state.errors.getLoanRenewalDetails = null;
        },
      )
      .addCase(
        activeLoansActions.getLoanRenewalDetailsFailure,
        (state, action) => {
          state.loading.getLoanRenewalDetails = false;
          state.errors.getLoanRenewalDetails = action.payload.error;
        },
      )
      .addCase(activeLoansActions.updateLoanRenewalDetails, (state) => {
        state.loading.updateLoanRenewalDetails = true;
        state.errors.updateLoanRenewalDetails =
          initialState.errors.updateLoanRenewalDetails;
      })
      .addCase(activeLoansActions.updateLoanRenewalDetailsSuccess, (state) => {
        state.loading.updateLoanRenewalDetails = false;
      })
      .addCase(
        activeLoansActions.updateLoanRenewalDetailsFailure,
        (state, action) => {
          state.loading.updateLoanRenewalDetails = false;
          state.errors.updateLoanRenewalDetails = action.payload.error;
        },
      )
      .addCase(activeLoansActions.skipPersonalLoan, (state) => {
        state.loading.skipPersonalLoan = true;
        state.errors.skipPersonalLoan = initialState.errors.skipPersonalLoan;
      })
      .addCase(activeLoansActions.skipPersonalLoanSuccess, (state) => {
        state.loading.skipPersonalLoan = false;
      })
      .addCase(activeLoansActions.skipPersonalLoanFailure, (state, action) => {
        state.loading.skipPersonalLoan = false;
        state.errors.skipPersonalLoan = action.payload.error;
      })
      .addCase(activeLoansActions.getLatestStatus, (state) => {
        state.loading.cmJobLatestStatus = true;
      })
      .addCase(activeLoansActions.getLatestStatusSuccess, (state, action) => {
        state.loading.cmJobLatestStatus = false;
        state.cmJobLatestStatus = action.payload.response?.status;
      })
      .addCase(activeLoansActions.getLatestStatusFailure, (state, action) => {
        state.loading.cmJobLatestStatus = false;
        state.errors.cmJobLatestStatus = action.payload.error;
      })
      .addCase(activeLoansActions.getLMInsuranceDetails, (state) => {
        state.loading.getLMInsuranceDetails = true;
      })
      .addCase(
        activeLoansActions.getLMInsuranceDetailsSuccess,
        (state, action) => {
          state.loading.getLMInsuranceDetails = false;
          state.lmInsuranceDetails = action.payload.response;
        },
      )
      .addCase(
        activeLoansActions.getLMInsuranceDetailsFailure,
        (state, action) => {
          state.loading.getLMInsuranceDetails = false;
          state.errors.getLMInsuranceDetails = action.payload.error;
        },
      )

      .addCase(activeLoansActions.getInsuranceFeatureFlag, (state) => {
        state.loading.getInsuranceFlag = true;
      })
      .addCase(
        activeLoansActions.getInsuranceFeatureFlagSuccess,
        (state, action) => {
          state.loading.getInsuranceFlag = false;
          state.isInsuranceUpsellRestricted =
            action.payload.response?.restrictInsuranceUpsell;
          state.isEmploymentDetailsEditAllowed =
            action.payload.response?.allowEmploymentDetailEdit;
          state.isAgriDocFlowAllowed =
            action.payload.response?.allowAgriLoanBookingWithoutLandDocumentExposureCheck;
          state.isGuarantorDetailsRequired =
            action.payload.response?.requireGuarantorDetail;
          state.isOrnamentsCheckerActive =
            action.payload?.response?.isCheckerForOrnamentValuationActive;
          state.overrideLandDocumentDuplicateCheck =
            action.payload.response?.overrideLandDocumentDuplicateCheck;
          state.isLoanAmountSentToCustomerForBT =
            action.payload.response?.sendLoanAmountToCustomerAccountOnlyInBt;
        },
      )
      .addCase(
        activeLoansActions.getInsuranceFeatureFlagFailure,
        (state, action) => {
          state.loading.getInsuranceFlag = false;
          state.errors.getInsuranceFlag = action.payload.error;
        },
      )

      .addCase(activeLoansActions.skipOfficeReview, (state) => {
        state.loading.skipOfficeReview = true;
      })
      .addCase(activeLoansActions.skipOfficeReviewSuccess, (state) => {
        state.loading.skipOfficeReview = false;
      })
      .addCase(activeLoansActions.skipOfficeReviewFailure, (state, action) => {
        state.loading.skipOfficeReview = false;
        state.errors.skipOfficeReview = action.payload.error;
      })

      .addCase(activeLoansActions.getLandDocumentDetail, (state) => {
        state.loading.getLandDocumentDetail = true;
      })
      .addCase(
        activeLoansActions.getLandDocumentDetailSuccess,
        (state, action) => {
          state.landDocumentDetail = action.payload.response;
          state.loading.getLandDocumentDetail = false;
        },
      )
      .addCase(
        activeLoansActions.getLandDocumentDetailFailure,
        (state, action) => {
          state.loading.getLandDocumentDetail = false;
          state.errors.getLandDocumentDetail = action.payload.error;
        },
      )

      .addCase(activeLoansActions.deleteLandDocumentDetail, (state) => {
        state.loading.deleteLandDocumentDetail = true;
      })
      .addCase(activeLoansActions.deleteLandDocumentDetailSuccess, (state) => {
        state.loading.deleteLandDocumentDetail = false;
      })
      .addCase(
        activeLoansActions.deleteLandDocumentDetailFailure,
        (state, action) => {
          state.loading.deleteLandDocumentDetail = false;
          state.errors.deleteLandDocumentDetail = action.payload.error;
        },
      )

      .addCase(activeLoansActions.getMatchingLandDocumentDetail, (state) => {
        state.loading.getMatchingLandDocumentDetail = true;
      })
      .addCase(
        activeLoansActions.getMatchingLandDocumentDetailSuccess,
        (state, action) => {
          state.matchingLandDocumentDetail =
            action.payload.response.crLandMatchingDocumentList;
          state.loading.getMatchingLandDocumentDetail = false;
        },
      )
      .addCase(
        activeLoansActions.getMatchingLandDocumentDetailFailure,
        (state, action) => {
          state.loading.getMatchingLandDocumentDetail = false;
          state.errors.getMatchingLandDocumentDetail = action.payload.error;
        },
      )

      .addCase(activeLoansActions.postLandDocumentDetail, (state) => {
        state.loading.postLandDocumentDetail = true;
      })
      .addCase(activeLoansActions.postLandDocumentDetailSuccess, (state) => {
        state.loading.postLandDocumentDetail = false;
      })
      .addCase(
        activeLoansActions.postLandDocumentDetailFailure,
        (state, action) => {
          state.loading.postLandDocumentDetail = false;
          state.errors.postLandDocumentDetail = action.payload.error;
        },
      )

      .addCase(activeLoansActions.markLandDocument, (state) => {
        state.loading.markLandDocument = true;
      })
      .addCase(activeLoansActions.markLandDocumentSuccess, (state) => {
        state.loading.markLandDocument = false;
      })
      .addCase(activeLoansActions.markLandDocumentFailure, (state, action) => {
        state.loading.markLandDocument = false;
        state.errors.markLandDocument = action.payload.error;
      })

      .addCase(activeLoansActions.getLandStateList, (state) => {
        state.loading.getLandStateList = true;
      })
      .addCase(activeLoansActions.getLandStateListSuccess, (state, action) => {
        state.loading.getLandStateList = false;
        state.landStateList = action.payload.response;
      })
      .addCase(activeLoansActions.getLandStateListFailure, (state, action) => {
        state.loading.getLandStateList = false;
        state.errors.getLandStateList = action.payload.error;
      })

      .addCase(activeLoansActions.getLandOwnerBorrowerList, (state) => {
        state.loading.getLandOwnerBorrowerList = true;
      })
      .addCase(
        activeLoansActions.getLandOwnerBorrowerListSuccess,
        (state, action) => {
          state.loading.getLandOwnerBorrowerList = false;
          state.landOwnerBorrowerList = action.payload.response;
        },
      )
      .addCase(
        activeLoansActions.getLandOwnerBorrowerListFailure,
        (state, action) => {
          state.loading.getLandOwnerBorrowerList = false;
          state.errors.getLandOwnerBorrowerList = action.payload.error;
        },
      )

      .addCase(activeLoansActions.markLandDocumentAsSame, (state) => {
        state.loading.markLandDocumentAsSame = true;
      })
      .addCase(activeLoansActions.markLandDocumentAsSameSuccess, (state) => {
        state.loading.markLandDocumentAsSame = false;
      })
      .addCase(
        activeLoansActions.markLandDocumentAsSameFailure,
        (state, action) => {
          state.loading.markLandDocumentAsSame = false;
          state.errors.markLandDocumentAsSame = action.payload.error;
        },
      )

      .addCase(activeLoansActions.getAgriExposureAmount, (state) => {
        state.loading.getAgriExposureAmount = true;
      })
      .addCase(
        activeLoansActions.getAgriExposureAmountSuccess,
        (state, action) => {
          state.loading.getAgriExposureAmount = false;
          state.totalAgriLoanAmountAllowed =
            action.payload.response?.totalLoanAmountAllowed;
        },
      )
      .addCase(
        activeLoansActions.getAgriExposureAmountFailure,
        (state, action) => {
          state.loading.getAgriExposureAmount = false;
          state.errors.getAgriExposureAmount = action.payload.error;
        },
      )
      .addCase(activeLoansActions.activateLoanMaker, (state) => {
        state.loading.activateLoanMaker = true;
      })
      .addCase(activeLoansActions.activateLoanMakerSuccess, (state) => {
        state.loading.activateLoanMaker = false;
      })
      .addCase(activeLoansActions.activateLoanMakerFailure, (state, action) => {
        state.loading.activateLoanMaker = false;
        state.errors.activateLoanMaker = action.payload.error;
      })

      .addCase(activeLoansActions.getPrefilledLoanMakerDetails, (state) => {
        state.loading.getPrefilledLoanMakerDetails = true;
      })
      .addCase(
        activeLoansActions.getPrefilledLoanMakerDetailsSuccess,
        (state, action) => {
          state.loading.getPrefilledLoanMakerDetails = false;
          state.loanMakerPrefilledDetails = action.payload.response;
        },
      )
      .addCase(
        activeLoansActions.getPrefilledLoanMakerDetailsFailure,
        (state, action) => {
          state.loading.getPrefilledLoanMakerDetails = false;
          state.errors.getPrefilledLoanMakerDetails = action.payload.error;
        },
      )

      .addCase(activeLoansActions.getPostLoanDocsFlag, (state) => {
        state.loading.getPostLoanDocsFlag = true;
      })
      .addCase(
        activeLoansActions.getPostLoanDocsFlagSuccess,
        (state, action) => {
          state.loading.getPostLoanDocsFlag = false;
          state.postLoanDocsFlag = action.payload.response;
        },
      )
      .addCase(
        activeLoansActions.getPostLoanDocsFlagFailure,
        (state, action) => {
          state.loading.getPostLoanDocsFlag = false;
          state.errors.getPostLoanDocsFlag = action.payload.error;
        },
      )

      .addCase(activeLoansActions.getLoanRequirementReason, (state) => {
        state.loading.getLoanRequirementReason = true;
      })
      .addCase(
        activeLoansActions.getLoanRequirementReasonSuccess,
        (state, action) => {
          state.loading.getLoanRequirementReason = false;
          state.loanRequirementReason =
            action.payload.response?.loanRequirementReason;
        },
      )
      .addCase(
        activeLoansActions.getLoanRequirementReasonFailure,
        (state, action) => {
          state.loading.getLoanRequirementReason = false;
          state.errors.getLoanRequirementReason = action.payload.error;
        },
      )

      .addCase(activeLoansActions.getActiveLoansTabsList, (state) => {
        state.loading.getActiveLoansTabsList = true;
      })
      .addCase(
        activeLoansActions.getActiveLoansTabsListSuccess,
        (state, action) => {
          state.loading.getActiveLoansTabsList = false;
          state.activeLoansTabsList =
            action.payload?.response?.activeLoanTabList;
        },
      )
      .addCase(
        activeLoansActions.getActiveLoansTabsListFailure,
        (state, action) => {
          state.loading.getActiveLoansTabsList = false;
          state.errors.getActiveLoansTabsList = action.payload.error;
        },
      )
      .addCase(activeLoansActions.rejectCr, (state) => {
        state.errors.rejectCrConfig = null;
        state.loading.rejectCr = true;
      })
      .addCase(activeLoansActions.rejectCrSuccess, (state, action) => {
        state.loading.rejectCr = false;
        state.rejectCrDetail = action.payload.response;
      })
      .addCase(activeLoansActions.rejectCrFailure, (state, action) => {
        state.loading.rejectCr = false;
        state.errors.rejectCr = action.payload.error;
      })
      .addCase(activeLoansActions.getRejectCrConfig, (state) => {
        state.errors.rejectCrConfig = null;
        state.loading.rejectCrConfig = true;
      })
      .addCase(activeLoansActions.getRejectCrConfigSuccess, (state, action) => {
        state.loading.rejectCrConfig = false;
        state.rejectCrConfig = action.payload.response;
      })
      .addCase(activeLoansActions.getRejectCrConfigFailure, (state, action) => {
        state.loading.rejectCrConfig = false;
        state.errors.rejectCrConfig = action.payload.error;
      })
      .addCase(activeLoansActions.sendBackCr, (state) => {
        state.errors.sendBackCr = null;
        state.loading.sendBackCr = true;
      })
      .addCase(activeLoansActions.sendBackCrSuccess, (state, action) => {
        state.loading.sendBackCr = false;
        state.sendBackCrDetail = action.payload.response;
      })
      .addCase(activeLoansActions.sendBackCrFailure, (state, action) => {
        state.loading.sendBackCr = false;
        state.errors.sendBackCr = action.payload.error;
      })
      .addCase(activeLoansActions.getSendBackCrConfig, (state) => {
        state.errors.sendBackCrConfig = null;
        state.loading.sendBackCrConfig = true;
      })
      .addCase(
        activeLoansActions.getSendBackCrConfigSuccess,
        (state, action) => {
          state.loading.sendBackCrConfig = false;
          state.sendBackCrConfig = action.payload.response;
        },
      )
      .addCase(
        activeLoansActions.getSendBackCrConfigFailure,
        (state, action) => {
          state.loading.sendBackCrConfig = false;
          state.errors.sendBackCrConfig = action.payload.error;
        },
      )

      .addCase(activeLoansActions.getLoanCheckerStatus, (state) => {
        state.lenderApprovalDetails = null;
        state.errors.getLoanCheckerStatus = null;
        state.loading.getLoanCheckerStatus = true;
      })
      .addCase(
        activeLoansActions.getLoanCheckerStatusSuccess,
        (state, action) => {
          state.loading.getLoanCheckerStatus = false;
          state.lenderApprovalDetails = action.payload.response;
        },
      )
      .addCase(
        activeLoansActions.getLoanCheckerStatusFailure,
        (state, action) => {
          state.lenderApprovalDetails = null;
          state.loading.getLoanCheckerStatus = false;
          state.errors.getLoanCheckerStatus = action.payload.error;
        },
      )

      .addMatcher(
        (action) => action.type.startsWith("activeLoansPagination"),
        (state, action) => {
          state.pagination = activeLoansPaginationReducer(
            state.pagination,
            action,
          );
        },
      );
  },
});

const {
  resetActiveLoansState,
  applyFilters,
  toggleModals,
  setSelectedCR,
  resetFiltersState,
  resetLoanDetailsState,
  setActiveTab,
  setLoading: setActiveLoansLoading,
  setError: setActiveLoansError,
  setBtScoreCardDetails,
  setBtScoreCardConfig,
  setSelectedBtScoreCardMilestone,
  setIsBtScoreCardMilestoneCheckedByCm,
  setBtScoreCardCustomerDetails,
} = activeLoansSlice.actions;

export default activeLoansSlice.reducer;
export {
  resetActiveLoansState,
  initialState,
  applyFilters,
  toggleModals,
  setSelectedCR,
  resetFiltersState,
  resetLoanDetailsState,
  setActiveTab,
  setActiveLoansLoading,
  setActiveLoansError,
  setBtScoreCardDetails,
  setBtScoreCardConfig,
  setSelectedBtScoreCardMilestone,
  setIsBtScoreCardMilestoneCheckedByCm,
  setBtScoreCardCustomerDetails,
};
