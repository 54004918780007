import {RootState} from "app/store/store";

const activeLoansSelectors = {
  getCitiesList: (state: RootState) => {
    return Object.values(state.activeLoans.citiesById);
  },
  getCustomerRequests: (state: RootState) =>
    Object.values(state.activeLoans.customerRequestsById).filter((cr) => cr.id),
  getLoanRenewalDetailsByCrId: (state: RootState, crId: number) =>
    state.activeLoans.customerRequestsById[crId]?.loanRenewalDetails,
  getLoanTopUpAmountByCrId: (state: RootState, crId: number) =>
    state.activeLoans.customerRequestsById[crId]?.loanTopUpAmountDetails,
  getSelectedCR: (state: RootState) => state.activeLoans.selectedCr,
  getErrors: (state: RootState) => state.activeLoans.errors,
  getLoading: (state: RootState) => state.activeLoans.loading,
  getFilters: (state: RootState) => state.activeLoans.filters,
  getOpenModals: (state: RootState) => state.activeLoans.openModals,
  getLMJobDetails: (state: RootState) => state.activeLoans.lMJobDetails,
  getLMUserList: (state: RootState) => state.activeLoans.lMUsers,
  getLoanMakerFieldsFlag: (state: RootState) =>
    state.activeLoans.loanMakerInputFieldsFlag,
  getCmJobLatestStatus: (state: RootState) =>
    state.activeLoans.cmJobLatestStatus,
  getLmInsuranceDetails: (state: RootState) =>
    state.activeLoans.lmInsuranceDetails,

  getInsuranceFeatureFlag: (state: RootState) =>
    state.activeLoans.isInsuranceUpsellRestricted,

  getOverrideLandDocumentDuplicateCheck: (state: RootState) =>
    state.activeLoans.overrideLandDocumentDuplicateCheck,

  getLoanMakerPrefilledDetails: (state: RootState) =>
    state.activeLoans.loanMakerPrefilledDetails,

  getEmploymentDetailsEditFlag: (state: RootState) =>
    state.activeLoans.isEmploymentDetailsEditAllowed,
  getLandDocumentDetail: (state: RootState) =>
    state.activeLoans.landDocumentDetail,

  getMatchingLandDocumentDetail: (state: RootState) =>
    state.activeLoans.matchingLandDocumentDetail,

  getLandOwnerBorrowerList: (state: RootState) =>
    state.activeLoans.landOwnerBorrowerList,

  getAgriTotalLoanAmount: (state: RootState) =>
    state.activeLoans.totalAgriLoanAmountAllowed,

  getAgriFlowFlag: (state: RootState) => state.activeLoans.isAgriDocFlowAllowed,

  getLoanRequirementReason: (state: RootState) =>
    state.activeLoans.loanRequirementReason,

  getPostLoanDocsFlag: (state: RootState) => state.activeLoans.postLoanDocsFlag,
  getLandStateList: (state: RootState) => state.activeLoans.landStateList,
  getPagination: (state: RootState) => state.activeLoans.pagination,
  getActiveTab: (state: RootState) => state.activeLoans.activeTab,
  getActiveLoansTabsList: (state: RootState) =>
    state.activeLoans.activeLoansTabsList,
  getGuarantorDetailsRequired: (state: RootState) =>
    state.activeLoans.isGuarantorDetailsRequired,
  getOrnamentCheckerFlag: (state: RootState) =>
    state.activeLoans.isOrnamentsCheckerActive,
  getRejectCrDetail: (state: RootState) => state.activeLoans.rejectCrDetail,
  getRejectCrConfig: (state: RootState) => state.activeLoans.rejectCrConfig,
  getSendBackCrDetail: (state: RootState) => state.activeLoans.sendBackCrDetail,
  getSendBackCrConfig: (state: RootState) => state.activeLoans.sendBackCrConfig,
  getLenderApprovalDetails: (state: RootState) =>
    state.activeLoans.lenderApprovalDetails,
  getBtScoreCardDetailsByCrId: (state: RootState, crId: number) =>
    state.activeLoans.btScoreCardDetails[crId],
  getBtScoreCardConfig: (state: RootState) =>
    state.activeLoans.btScoreCardConfg,
  getSelectedBtScoreCardMilestone: (state: RootState) =>
    state.activeLoans.selectedBtScoreCardMilestone,
  getBtScoreDetailsByMilestone: (
    state: RootState,
    crId: number,
    selectedMilestone: string,
  ) =>
    state.activeLoans.btScoreCardDetails[crId]?.entityRatingByEnum[
      selectedMilestone
    ],
  selectBtScoreCardMilestones: function(state: RootState, crId: number) {
    const details = this.getBtScoreCardDetailsByCrId(state, crId);
    if (details && details.entityRatingByEnum) {
      return Object.keys(details.entityRatingByEnum);
    }
    return [];
  },
  getBtScoreCardMilestoneCheckedByCm: (state: RootState) =>
    state.activeLoans.isBtScoreMilestoneCheckedByCm,

  getBtScoreCardCustomerDetailsByCrId: (state: RootState, crId: number) =>
    state.activeLoans.btScoreCardCustomerDetails[crId],
  getLoanAmountSentToCustomerForBT: (state: RootState) =>
    state.activeLoans.isLoanAmountSentToCustomerForBT,
};

export default activeLoansSelectors;
