import React, {useEffect, useCallback, useState} from "react";
import Accordoin from "../../CommonComponents/Accordoin";
import {Box} from "@mui/material";
import NomineeDetails from "./NomineeDetails";
import OccupationDetails from "./OccupationDetails";
import LenderDetails from "./LenderDocuments";
import KYCDetails from "./KYCDetails";
import PledgeCardDetails from "./PledgeCardDetails";
import CreditChecks from "./CreditChecks";
import {
  activeLoansActions,
  activeLoansSelectors,
} from "app/store/activeLoans";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import BTScoreIntroductoryCard from "./BTScoreIntroductoryCard";
import {setIsBtScoreCardMilestoneCheckedByCm} from "app/store/activeLoans/activeLoans.reducer";

const Fresh = ({
  selectedPayload,
  isFresh,
  currentMileStone,
  showTakeoverKycAlert,
  setShowTakeoverKycAlert,
  setAllChecked,
}) => {
  const dispatch = useAppDispatch();
  const isGuarantorDetailsAccordionRequired = useAppSelector(
    activeLoansSelectors.getGuarantorDetailsRequired,
  );

  const [checkList, setCheckList] = useState({
    cibilConsent: false,
    nomineeDetails: false,
    occupationalDetails: false,
    lenderDocuments: false,
    kycDetails: false,
    pledgeCardDetails: false,
  });

  const handleCheckBoxClick = (key) => {
    setCheckList((prevCheckList) => {
      const updatedCheckList = {
        ...prevCheckList,
        [key]: !prevCheckList[key],
      };
      return updatedCheckList;
    });
  };

  const handleAllCheckbox = useCallback(() => {
    const allChecked = Object.entries(checkList)
      .filter(([key, value]) =>
        key === "guarantorDetails" ? isGuarantorDetailsAccordionRequired : true,
      )
      .every(([key, value]) => value);
    setAllChecked(allChecked);
  }, [checkList, setAllChecked]);

  const NomineeDetailsWrapper = React.memo(() => (
    <NomineeDetails
      crId={selectedPayload.id}
      selectedPayload={selectedPayload}
    />
  ));

  const OccupationDetailsWrapper = React.memo(() => (
    <OccupationDetails
      crId={selectedPayload.id}
      selectedPayload={selectedPayload}
    />
  ));

  const LenderDetailsWrapper = React.memo(() => (
    <LenderDetails
      selectedPayload={selectedPayload}
      currentMileStone={currentMileStone}
    />
  ));

  const KYCDetailsWrapper = React.memo(() => (
    <KYCDetails
      isFresh={isFresh}
      selectedPayload={selectedPayload}
      setShowTakeoverKycAlert={setShowTakeoverKycAlert}
    />
  ));

  const PledgeCardDetailsWrapper = React.memo(() => {
    return <PledgeCardDetails selectedPayload={selectedPayload} />;
  });

  const CreditChecksWrapper = React.memo(() => (
    <CreditChecks crId={selectedPayload.id} />
  ));

  useEffect(() => {
    dispatch(
      activeLoansActions.getInsuranceFeatureFlag({
        crId: selectedPayload.id,
      }),
    );
    dispatch(
      activeLoansActions.getBtScoreCardConfig({
        crId: selectedPayload.id,
        source: "CUSTOMER_REQUEST_BT",
      }),
    );
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setIsBtScoreCardMilestoneCheckedByCm(false));
    };
  }, []);

  useEffect(() => {
    handleAllCheckbox();
  }, [handleAllCheckbox]);

  return (
    <div>
      <Box mt={2}>
        <BTScoreIntroductoryCard />
      </Box>
      <Accordoin
        name={"cibilConsent"}
        Component={CreditChecksWrapper}
        title="Credit Score Details"
        showCheckBox={true}
        checked={checkList.cibilConsent}
        setChecked={handleCheckBoxClick}
      />
      <Accordoin
        name={"nomineeDetails"}
        Component={NomineeDetailsWrapper}
        title="Nominee Details"
        showCheckBox={true}
        checked={checkList.nomineeDetails}
        setChecked={handleCheckBoxClick}
      />
      <Accordoin
        name={"occupationalDetails"}
        Component={OccupationDetailsWrapper}
        title="Occupation Details"
        showCheckBox={true}
        checked={checkList.occupationalDetails}
        setChecked={handleCheckBoxClick}
      />
      <Accordoin
        name={"kycDetails"}
        Component={KYCDetailsWrapper}
        title="KYC Details"
        showAlert={showTakeoverKycAlert}
        showCheckBox={true}
        setChecked={handleCheckBoxClick}
        checked={checkList.kycDetails}
      />
      <Accordoin
        name={"pledgeCardDetails"}
        Component={PledgeCardDetailsWrapper}
        title="Pledge Card Details"
        showCheckBox={true}
        setChecked={handleCheckBoxClick}
        checked={checkList.pledgeCardDetails}
      />

      {selectedPayload.milestone !== "COMPLETED" &&
        selectedPayload.milestone !== "CLOSED" &&
        selectedPayload.milestone !== "CR_GOLD_LOAN_FRESH_CLOSED" &&
        selectedPayload.milestone !== "CR_GOLD_LOAN_BT_CLOSED" && (
          <Accordoin
            name={"lenderDocuments"}
            Component={LenderDetailsWrapper}
            title="Supplementary  Documents"
            showCheckBox={true}
            setChecked={handleCheckBoxClick}
            checked={checkList.lenderDocuments}
          />
        )}
    </div>
  );
};

export default Fresh;
