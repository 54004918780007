import React from "react";
import {RootState} from "app/store/store";
import {Typography, Box} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {useAppSelector} from "app/store/hooks";
import {activeLoansSelectors} from "app/store/activeLoans";

const BTScoreCardTips = ({crId}: { crId: number }) => {
  const selectedMilestone = useAppSelector(
    activeLoansSelectors.getSelectedBtScoreCardMilestone,
  );
  const {ruleMetaDataJson, entityRating} =
    useAppSelector((state: RootState) =>
      activeLoansSelectors.getBtScoreDetailsByMilestone(
        state,
        crId,
        selectedMilestone,
      ),
    ) ?? {};
  const {ratingLevelEnum} = entityRating ?? {};

  const {tips} = ruleMetaDataJson ?? {};

  if (ratingLevelEnum === "HIGH" || tips?.length === 0) {
    return null;
  }

  return (
    <>
      <Typography
        fontFamily="roboto"
        variant="subtitle2"
        fontSize="14px"
        lineHeight="22px"
        my={2}
      >
        Tips
      </Typography>
      {tips?.map((t) => (
        <Box
          borderRadius="4px"
          gap="10px"
          padding="8px 12px 8px 12px"
          bgcolor="#FAFAFA"
          border="1px solid #0000001F"
          mb={2}
        >
          <Box display="flex" gap={1}>
            <InfoOutlinedIcon />
            <Typography fontWeight={400} variant="body2" fontSize="14px">
              {t}
            </Typography>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default BTScoreCardTips;
