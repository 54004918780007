import {GoldApiError} from "app/typings/api/goldApi.types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import creditDetailsActions from "./creditDetails.actions";
import {
  CreditScoreDetails,
  CreditSessionStatus,
} from "app/models/loanRenewalRebook/creditDetails.model";
import {string} from "prop-types";

type LoadingErrorState =
  | "getCreditScoreDetails"
  | "initiateCreditConsent"
  | "getTakeoverCreditReport"
  | "getCreditSessionStatus";

export interface CreditDetailsState {
  creditScoreDetails: CreditScoreDetails | null;
  creditConsentLink: null;
  takeovercreditReport: string | null;
  creditSessionStatus: CreditSessionStatus | null;
  openModals: {
    creditConsent: boolean;
  };

  loading: Record<LoadingErrorState, boolean>;
  errors: Record<LoadingErrorState, GoldApiError | null>;
}

const initialState: CreditDetailsState = {
  creditScoreDetails: null,
  creditConsentLink: null,
  takeovercreditReport: null,
  creditSessionStatus: null,
  openModals: {
    creditConsent: false,
  },
  loading: {
    getCreditScoreDetails: false,
    initiateCreditConsent: false,
    getTakeoverCreditReport: false,
    getCreditSessionStatus: false,
  },

  errors: {
    getCreditScoreDetails: null,
    initiateCreditConsent: null,
    getTakeoverCreditReport: null,
    getCreditSessionStatus: null,
  },
};

const creditDetailsSlice = createSlice({
  name: "creditDetails",
  initialState,
  reducers: {
    toggleModals: (
      state,
      action: PayloadAction<{
        type: keyof typeof state.openModals;
        value: boolean;
      }>,
    ) => {
      const {type, value} = action.payload;
      state.openModals[type] = value;
    },
    creditReportHtmlReceived: (
      state,
      action: PayloadAction<{
        report: string;
      }>,
    ) => {
      state.takeovercreditReport = action.payload.report;
    },
    resetCreditDetails: (state) => {
      (state.creditConsentLink = null),
        (state.creditScoreDetails = null),
        (state.takeovercreditReport = null),
        (state.creditSessionStatus = null);
    },
    setLoading: (
      state,
      action: PayloadAction<{
        key: LoadingErrorState;
        value: boolean;
      }>,
    ) => {
      state.loading[action.payload.key] = action.payload.value;
    },
    setError: (
      state,
      action: PayloadAction<{
        key: LoadingErrorState;
        value: GoldApiError;
      }>,
    ) => {
      state.errors[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) =>
    builder

      .addCase(creditDetailsActions.getCreditScoreDetails, (state) => {
        state.loading.getCreditScoreDetails = true;
        state.errors.getCreditScoreDetails =
          initialState.errors.getCreditScoreDetails;
      })
      .addCase(
        creditDetailsActions.getCreditScoreDetailsSuccess,
        (state, action) => {
          state.creditScoreDetails = action.payload.response;
          state.loading.getCreditScoreDetails = false;
        },
      )
      .addCase(
        creditDetailsActions.getCreditScoreDetailsFailure,
        (state, action) => {
          state.loading.getCreditScoreDetails = false;
          state.errors.getCreditScoreDetails = action.payload.error;
        },
      )
      .addCase(creditDetailsActions.initiateCreditConsent, (state) => {
        state.loading.initiateCreditConsent = true;
        state.errors.initiateCreditConsent =
          initialState.errors.initiateCreditConsent;
      })
      .addCase(
        creditDetailsActions.initiateCreditConsentSuccess,
        (state, action) => {
          state.creditConsentLink = action.payload.response.link;
          state.loading.initiateCreditConsent = false;
        },
      )
      .addCase(
        creditDetailsActions.initiateCreditConsentFailure,
        (state, action) => {
          state.loading.initiateCreditConsent = false;
          state.errors.initiateCreditConsent = action.payload.error;
        },
      )

      .addCase(creditDetailsActions.getTakeoverCreditReportHtml, (state) => {
        state.loading.getTakeoverCreditReport = true;
        state.errors.getTakeoverCreditReport =
          initialState.errors.getTakeoverCreditReport;
      })

      .addCase(creditDetailsActions.getCreditSessionStatus, (state) => {
        state.loading.getCreditSessionStatus = true;
        state.errors.getCreditSessionStatus =
          initialState.errors.getCreditSessionStatus;
      })
      .addCase(
        creditDetailsActions.getCreditSessionStatusSuccess,
        (state, action) => {
          state.creditSessionStatus = action.payload.response;
          state.loading.getCreditSessionStatus = false;
        },
      )
      .addCase(
        creditDetailsActions.getCreditSessionStatusFailure,
        (state, action) => {
          state.loading.getCreditSessionStatus = false;
          state.errors.getCreditSessionStatus = action.payload.error;
        },
      ),
});

export default creditDetailsSlice.reducer;
const {
  toggleModals,
  creditReportHtmlReceived,
  setLoading: setCreditDetailsLoading,
  setError: setCreditDetailsError,
  resetCreditDetails,
} = creditDetailsSlice.actions;

export {
  toggleModals,
  creditReportHtmlReceived,
  setCreditDetailsLoading,
  setCreditDetailsError,
  resetCreditDetails,
};
