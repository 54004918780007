import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {leadActionTypes} from "./lead.actions";
import {GoldApi} from "../../infra/services/api";
import {PayloadAction} from "@reduxjs/toolkit";
import {
  AddNewLeadPayload,
  BulkLeadUploadPayload,
  GetAnalyticsPayload,
  GetLeadListPayload,
  GetParentLeadId,
  ParentLeadDocumentFileDetailIdPayload,
  ParentLeadIdPayload,
  ParentLeadMasterDocumentConfigIdPayload,
} from "app/infra/services/api/lead/lead.types";
import {leadActions} from "./index";
import {snackBarActions} from "../snackbar";
import {setLeadLoading, setParentLeadId, setCreditDetails, setParentLeadDocumentConfig, setParentLeadDocuments, setLeadError, removeParentLeadDocument} from "./lead.reducer";
import {GenericResponse} from "app/typings/api/goldApi.types";

const {
  getPartnerLeadListSuccess,
  getPartnerLeadListFailure,
  downloadCSVSuccess,
  downloadCSVFailure,
  addNewLeadSuccess,
  addNewLeadFailure,
  bulkLeadUploadSuccess,
  bulkLeadUploadFailure,
  getAnalyticsSuccess,
  getAnalyticsFailure,
  getLeadUnQualifiedReasonsFailure,
  getLeadUnQualifiedReasonsSuccess,
} = leadActions;

function* generalErrorFlow(action: PayloadAction<GenericResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message || "Something went wrong!!",
      variant: "error",
    }),
  );
}

function* getPartnerLeadList(action: PayloadAction<GetLeadListPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.lead.getPartnerLeadList, action.payload);
  if (error) {
    yield put(getPartnerLeadListFailure({response: null, error}));
  } else {
    yield put(getPartnerLeadListSuccess({response, error: null}));
  }
}

function* downloadCSVFlow() {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.lead.downloadCSV);
  if (error) {
    yield put(downloadCSVFailure({response: null, error}));
  } else {
    yield put(downloadCSVSuccess({response, error: null}));
    if (response.bulkUploadTemplate) {
      window.open(response.bulkUploadTemplate);
    }
  }
}

function* addNewLeadFlow(action: PayloadAction<AddNewLeadPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.lead.addNewLead, action.payload);
  if (error) {
    yield put(addNewLeadFailure({response: null, error}));
  } else {
    yield put(addNewLeadSuccess({response, error: null}));
    yield put(
      snackBarActions.open({
        message: "Lead added successfully",
        variant: "success",
      }),
    );
  }
}

function* bulkLeadUploadFlow(action: PayloadAction<BulkLeadUploadPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.lead.bulkLeadUpload, action.payload);
  if (error) {
    yield put(bulkLeadUploadFailure({response: null, error}));
  } else {
    yield put(bulkLeadUploadSuccess({response, error: null}));
    yield put(
      snackBarActions.open({
        message: "Lead File uploaded successfully",
        variant: "success",
      }),
    );
  }
}

function* getAnalyticsFlow(action: PayloadAction<GetAnalyticsPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.lead.getAnalytics, action.payload);
  if (error) {
    yield put(getAnalyticsFailure({response: null, error}));
  } else {
    yield put(getAnalyticsSuccess({response, error: null}));
  }
}

function* getLeadUnQualifiedReasonsFlow() {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.lead.getLeadUnQualifiedReasons);
  if (error) {
    yield put(getLeadUnQualifiedReasonsFailure({response: null, error}));
  } else {
    yield put(getLeadUnQualifiedReasonsSuccess({response, error: null}));
  }
}

function* getParentLeadIdFlow(action: PayloadAction<GetParentLeadId>) {
  yield put(
    setLeadLoading({
      key: "getParentLeadId",
      value: true,
    }),
  );
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.lead.getParentLeadId, action.payload);

  if (error) {
    yield put(setLeadLoading({key: "getParentLeadId", value: false}));
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!!",
        variant: "error",
      }),
    );
  } else {
    yield put(setParentLeadId({response}));
  }
  yield put(
    setLeadLoading({
      key: "getParentLeadId",
      value: false,
    }),
  );
}

function* getParentLeadIdCreditDetailFlow(action: PayloadAction<ParentLeadIdPayload>) {
  yield put(
    setLeadLoading({
      key: "getParentLeadIdCreditDetail",
      value: true,
    }),
  );
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.lead.getParentLeadIdCreditDetail, action.payload);

  if (error) {
    yield put(setLeadLoading({key: "getParentLeadIdCreditDetail", value: false}));
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!!",
        variant: "error",
      }),
    );
  } else {
    yield put(setCreditDetails({response, leadId: action.payload.parentLeadId}));
  }
  yield put(
    setLeadLoading({
      key: "getParentLeadIdCreditDetail",
      value: false,
    }),
  );
}

function* getParentLeadDocumentConfigFlow(action: PayloadAction<ParentLeadIdPayload>) {
  yield put(setLeadLoading({key: "getParentLeadDocumentConfig", value: true}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.lead.getParentLeadDocumentConfig, action.payload);
  if (error) {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!!",
        variant: "error",
      }),
    );
    yield put(setLeadError({key: "getParentLeadDocumentConfig", value: error}));
  } else {
    yield put(setParentLeadDocumentConfig(response));
  }
  yield put(setLeadLoading({key: "getParentLeadDocumentConfig", value: false}));
};

function* getParentLeadDocumentFlow(
  action: PayloadAction<ParentLeadIdPayload & ParentLeadMasterDocumentConfigIdPayload>,
) {
  yield put(setLeadLoading({key: "getParentLeadDocument", value: true}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.lead.getParentLeadDocument, action.payload);
  if (error) {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!!",
        variant: "error",
      }),
    );
    yield put(setLeadError({key: "getParentLeadDocument", value: error}));
  } else {
    yield put(setParentLeadDocuments(response));
  }
  yield put(setLeadLoading({key: "getParentLeadDocument", value: false}));
}

function* updateParentLeadDocumentFlow(
  action: PayloadAction<
    ParentLeadIdPayload & ParentLeadMasterDocumentConfigIdPayload & { formData: FormData }
  >,
) {
  yield put(setLeadLoading({key: "updateParentLeadDocument", value: true}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.lead.updateParentLeadDocument, action.payload);
  if (error) {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!!",
        variant: "error",
      }),
    );
    yield put(setLeadError({key: "updateParentLeadDocument", value: error}));
  } else {
    yield put(leadActions.getParentLeadDocumentConfig({
      parentLeadId: action.payload.parentLeadId,
    }));
    yield put(
      snackBarActions.open({
        message: "Document uploaded successfully",
        variant: "success",
      }),
    );
  }
  yield put(setLeadLoading({key: "updateParentLeadDocument", value: false}));
}

function* deleteParentLeadDocumentFlow(
  action: PayloadAction<
    ParentLeadIdPayload &
      ParentLeadDocumentFileDetailIdPayload &
      ParentLeadMasterDocumentConfigIdPayload
  >,
) {
  yield put(setLeadLoading({key: "deleteParentLeadDocument", value: true}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.lead.deleteParentLeadDocument, action.payload);
  if (error) {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!!",
        variant: "error",
      }),
    );
    yield put(setLeadError({key: "deleteParentLeadDocument", value: error}));
  } else {
    yield put(
      removeParentLeadDocument({
        parentLeadId: action.payload.parentLeadId,
        parentLeadDocumentFileDetailId: action.payload.parentLeadDocumentFileDetailId,
        parentLeadMasterDocumentConfigId: action.payload.parentLeadMasterDocumentConfigId,
      }),
    );
    yield put(
      snackBarActions.open({
        message: "Document deleted successfully",
        variant: "success",
      }),
    );
  }
  yield put(setLeadLoading({key: "deleteParentLeadDocument", value: false}));
}

function* updateLeadOnLSQFlow(
  action: PayloadAction<ParentLeadIdPayload>,
) {
  yield put(setLeadLoading({key: "updateLeadOnLSQ", value: true}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.lead.updateLeadOnLSQ, action.payload);
  if (error) {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!!",
        variant: "error",
      }),
    );
    yield put(setLeadError({key: "updateLeadOnLSQ", value: error}));
  } else {
    yield put(
      snackBarActions.open({
        message: "Lead updated successfully",
        variant: "success",
      }),
    );
  }
  yield put(setLeadLoading({key: "updateLeadOnLSQ", value: false}));
}

export default function* leadSaga() {
  yield takeLatest(leadActionTypes.GetPartnerLeadList, getPartnerLeadList);
  yield takeLatest(leadActionTypes.GetPartnerLeadListFailure, generalErrorFlow);
  yield takeLatest(leadActionTypes.DownloadCSV, downloadCSVFlow);
  yield takeLatest(leadActionTypes.DownloadCSVFailure, generalErrorFlow);
  yield takeLatest(leadActionTypes.AddNewLead, addNewLeadFlow);
  yield takeLatest(leadActionTypes.AddNewLeadFailure, generalErrorFlow);
  yield takeLatest(leadActionTypes.BulkLeadUpload, bulkLeadUploadFlow);
  yield takeLatest(leadActionTypes.BulkLeadUploadFailure, generalErrorFlow);
  yield takeLatest(leadActionTypes.GetAnalytics, getAnalyticsFlow);
  yield takeLatest(leadActionTypes.GetAnalyticsFailure, generalErrorFlow);
  yield takeLatest(leadActionTypes.GetLeadUnQualifiedReasons, getLeadUnQualifiedReasonsFlow);
  yield takeLatest(leadActionTypes.GetLeadUnQualifiedReasonsFailure, generalErrorFlow);
  yield takeLatest(leadActionTypes.GetParentLeadId, getParentLeadIdFlow);
  yield takeLatest(leadActionTypes.GetParentLeadIdCreditDetail, getParentLeadIdCreditDetailFlow);
  yield takeLatest(leadActionTypes.GetParentLeadDocumentConfig, getParentLeadDocumentConfigFlow);
  yield takeLatest(leadActionTypes.GetParentLeadDocument, getParentLeadDocumentFlow);
  yield takeLatest(leadActionTypes.UpdateParentLeadDocument, updateParentLeadDocumentFlow);
  yield takeLatest(leadActionTypes.DeleteParentLeadDocument, deleteParentLeadDocumentFlow);
  yield takeLatest(leadActionTypes.UpdateLeadOnLSQ, updateLeadOnLSQFlow);
}
