import React, {useCallback, useEffect, useState} from "react";
import {RootState} from "app/store/store";
import {Box, Typography, Tooltip} from "@mui/material";
import GuarantorProfileDetails from "./GuarantorProfileDetails";
import GuaranterDocumentDetails from "./GuarantorDocumentDetails";
import GuarantorCreditCheck from "./GuarantorCreditCheck";
import GuarantorSupplementDocuments from "./GuarantorSupplementDocuments";
import GuarantorAddressDetails from "./GuarantorAddressDetails";
import InfoIcon from "@mui/icons-material/Info";
import {useAppSelector, useSnackBar} from "app/store/hooks";
import {activeLoansSelectors} from "app/store/activeLoans";
import fetchEmploymentDetails from "api/business/fetchAdminEmploymentDetails";

const GuarantorDetails = ({crId}: { crId: number }) => {
  const {showError} = useSnackBar();
  const selectedMilestone = useAppSelector(
    activeLoansSelectors.getSelectedBtScoreCardMilestone,
  );
  const {entityRating} =
    useAppSelector((state: RootState) =>
      activeLoansSelectors.getBtScoreDetailsByMilestone(
        state,
        crId,
        selectedMilestone,
      ),
    ) ?? {};

  const {ratingLevelEnum} = entityRating ?? {};

  const [guarantorOccupation, setGuarantorOccupation] = useState("");

  const fetchOccupationalDetails = useCallback(async () => {
    const {error, response} = await fetchEmploymentDetails({
      crId,
      crEmploymentDetailType: "GUARANTOR_EMPLOYMENT_DETAIL",
    });

    if (!error) {
      setGuarantorOccupation(response?.occupationTypeDisplayValue);
    } else {
      showError(error?.message || "not able to fetch occupdation details");
    }
  }, [crId, showError]);

  useEffect(() => {
    fetchOccupationalDetails();
  }, []);

  return (
    <>
      <Box
        bgcolor="#FAFAFA"
        p="24px 50px 24px 24px"
        borderRight="1px solid #0000001F"
        borderLeft="1px solid #0000001F"
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6" fontSize="20px" fontWeight={500}>
            Guarantor's Details
          </Typography>
          {ratingLevelEnum !== "HIGH" && (
            <Tooltip title="Refer to tips section" placement="left-start">
              <InfoIcon
                sx={{
                  color: "#B23842",
                }}
              />
            </Tooltip>
          )}
        </Box>

        <Box my={2}>
          <GuarantorProfileDetails guarantorOccupation={guarantorOccupation} />
        </Box>
        <Typography variant="h6" fontWeight={500} fontSize="20px">
          KYC Documents
        </Typography>
        <Box my={2}>
          <GuaranterDocumentDetails />
        </Box>

        <Box my={4} display="flex" gap={1}>
          <GuarantorCreditCheck />
          <GuarantorSupplementDocuments />
        </Box>
        <Typography variant="h6" fontWeight={500} fontSize="20px">
          Address
        </Typography>
        <Box my={2}>
          <GuarantorAddressDetails />
        </Box>
      </Box>
    </>
  );
};

export default GuarantorDetails;
