import React, {useEffect} from "react";
import {RootState} from "app/store/store";
import {Grid} from "@mui/material";
import {IGDialog, IGLoading} from "app/components";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {
  activeLoansActions,
  activeLoansSelectors,
} from "app/store/activeLoans";
import {toggleModals} from "app/store/activeLoans/activeLoans.reducer";
import GuarantorDetails from "./GuarantorDetails";
import BTScoreCardMilestones from "./BTScoreCardMilestones";
import BTScoreCardCustomerDetails from "./BTScoreCardCustomerDetails";
import {customerPersonalDetailsEnum} from "app/enums/accounts";
import {
  personalDetailsActions,
  personalDetailsSelectors,
} from "app/store/loanRenewalRebook/personalDetails";
import {
  creditDetailsActions,
  creditDetailsSelectors,
} from "app/store/loanRenewalRebook/creditDetails";
import BtScoreCardDisclaimer from "./BTScoreCardDiscalaimer";
import BtScoreSection from "./BTScoreSection";

const BTScoreCard = () => {
  const dispatch = useAppDispatch();
  const isMilestoneCheckedByCm = useAppSelector(
    activeLoansSelectors.getBtScoreCardMilestoneCheckedByCm,
  );
  const selectedCr = useAppSelector(activeLoansSelectors.getSelectedCR);
  const {id: crId} = selectedCr ?? {};
  const {btScoreCard: isBtScoreCardModalOpen} = useAppSelector(
    activeLoansSelectors.getOpenModals,
  );
  const selectedMilestone = useAppSelector(
    activeLoansSelectors.getSelectedBtScoreCardMilestone,
  );
  const {ruleMetaDataJson} =
    useAppSelector((state: RootState) =>
      activeLoansSelectors.getBtScoreDetailsByMilestone(
        state,
        Number(crId),
        selectedMilestone,
      ),
    ) ?? {};

  const {headerDisplayName} = ruleMetaDataJson ?? {};

  const {
    getKycDocuments: iskycDocLoading,
    getGuarantorPersonalDetails: isPersonalDetailsLoading,
    getGuarantorAddressDetails: isAddressDetailsLoading,
  } = useAppSelector(personalDetailsSelectors.getLoading);
  const {
    getBtScoreCardDetails: isBtScoreCardDetailsLoading,
    getBtScoreCardCustomerDetails: isBtScoreCardCustomerDetailsLoading,
  } = useAppSelector(activeLoansSelectors.getLoading);
  const {getCreditSessionStatus: isCreditSessionLoading} = useAppSelector(
    creditDetailsSelectors.getLoading,
  );

  const isLoading =
    iskycDocLoading ||
    isPersonalDetailsLoading ||
    isAddressDetailsLoading ||
    isBtScoreCardDetailsLoading ||
    isBtScoreCardCustomerDetailsLoading;

  const initializeDataFetch = () => {
    if (crId) {
      dispatch(
        activeLoansActions.getBtScoreCardDetails({
          crId,
        }),
      );
      dispatch(
        activeLoansActions.getBtScoreCardCustomerDetails({
          crId,
        }),
      );
      dispatch(
        personalDetailsActions.getGuarantorPersonalDetails({
          crId,
          personalDetailType:
            customerPersonalDetailsEnum.GUARANTOR_PERSONAL_DETAIL,
        }),
      );

      dispatch(
        personalDetailsActions.getKycDocuments({
          crId,
          isRenewRebook: false,
          documentTypeList: [
            "GUARANTOR_AADHAAR",
            "GUARANTOR_PAN",
            "GUARANTOR_CO_BORROWER_FORM",
            "GUARANTOR_BANK_STATEMENT",
            "GUARANTOR_POST_DATED_CHEQUE",
            "GUARANTOR_RESIDENCE_PROOF",
          ],
        }),
      );

      dispatch(
        personalDetailsActions.getGuarantorAddressDetails({
          crId,
          addressType: [
            "GUARANTOR_RESIDENTIAL_ADDRESS",
            "GUARANTOR_PERMANENT_ADDRESS",
          ],
        }),
      );

      dispatch(
        creditDetailsActions.getCreditSessionStatus({
          crId,
          cibilConsentType: "GUARANTOR_CIBIL_CONSENT",
        }),
      );
    }
  };

  useEffect(() => {
    if (crId) {
      initializeDataFetch();
    }
  }, []);

  return (
    <IGDialog
      fullScreen
      hideFooter
      title={`BT Score Card / ${headerDisplayName}`}
      titleProps={{
        fontWeight: 400,
        fontSize: "1.2rem",
      }}
      open={isBtScoreCardModalOpen}
      onClose={() =>
        dispatch(
          toggleModals({
            type: "btScoreCard",
            value: false,
          }),
        )
      }
    >
      {isLoading ? (
        <IGLoading height="10vh" />
      ) : (
        <Grid container overflow="hidden">
          <Grid item xs={3}>
            <BTScoreCardCustomerDetails crId={Number(crId)} />
            <BTScoreCardMilestones crId={Number(crId)} />
          </Grid>

          <Grid
            item
            position="relative"
            xs={6}
            sx={{
              overflow: "scroll",
              scrollbarWidth: "thin",
            }}
            maxHeight="90vh"
          >
            <GuarantorDetails crId={Number(crId)} />
            {!isMilestoneCheckedByCm && <BtScoreCardDisclaimer />}
          </Grid>

          <Grid item xs={3}>
            <BtScoreSection crId={Number(crId)} />
          </Grid>
        </Grid>
      )}
    </IGDialog>
  );
};

export default BTScoreCard;
