import React from "react";
import {Box, Typography, List, ListItemButton} from "@mui/material";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {activeLoansSelectors} from "app/store/activeLoans";
import {setSelectedBtScoreCardMilestone} from "app/store/activeLoans/activeLoans.reducer";
import styled from "@emotion/styled";

const StyledList = styled(List)`
  & .Mui-selected,
  & .Mui-selected:hover {
    background-color: rgba(25, 118, 210, 0.08);
  }

  & .MuiListItemButton-root:hover {
    background-color: rgba(0, 0, 0, 0.38);
  }
  & .MuiListItemButton-root {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: flex-start;

    & .milestone-name {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      padding: 8px;
      text-transform: capitalize;
      color: #00000061;
    }
    & .selected {
      color: #1565c0;
    }
  }
`;
const BTScoreCardMilestones = ({crId}: { crId: number }) => {
  const dispatch = useAppDispatch();
  const selectedItem = useAppSelector(
    activeLoansSelectors.getSelectedBtScoreCardMilestone,
  );
  const milestones = useAppSelector((state) =>
    activeLoansSelectors.selectBtScoreCardMilestones(state, crId),
  );

  return (
    <>
      <Box mt={2} px="16px">
        <StyledList>
          {milestones?.map((item: any) => {
            const isSelected = selectedItem === item;
            return (
              <ListItemButton
                key={item}
                selected={isSelected}
                onClick={() => {
                  dispatch(setSelectedBtScoreCardMilestone(item));
                }}
              >
                <Typography
                  className={`milestone-name ${isSelected ? "selected" : ""}`}
                  component="header"
                >
                  {item.replaceAll("_", " ").toLowerCase()}
                </Typography>
                <Box p={1}>
                  <KeyboardArrowRightOutlinedIcon
                    className={isSelected ? "selected" : ""}
                  />
                </Box>
              </ListItemButton>
            );
          })}
        </StyledList>
      </Box>
    </>
  );
};

export default BTScoreCardMilestones;
