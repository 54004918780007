import {PayloadAction} from "@reduxjs/toolkit";
import {
  GetActiveLoansCRListPayload,
  AssignCRToMePayload,
  SendBackToSchedulingPayload,
  GetLMUsersListPayload,
  AssignOrRemoveCurrentLMPayload,
  ActiveLoansResponse,
  CloseCurrentLMPayload,
  BlackListLoanPayload,
  CrIdPayload,
  LoanAmountUpdateRequestPayload,
  LoanRenewalAmountDetailsPayload,
  LatestStatusPayload,
  LMInsuranceDetailsPayload,
  SkipOfficeReviewPayload,
  LandDocumentDetailPayload,
  MarkLandDocumentPayload,
  RejectCrPayload,
  SendBackCrPayload,
  GetBtScoreCardConfig,
} from "../../infra/services/api/activeLoans/types";
import {GoldApi} from "app/infra/services/api";
import {
  call,
  getContext,
  put,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import {
  activeLoansActions,
  activeLoansActionTypes,
} from "./activeLoans.action";
import {snackBarActions} from "../snackbar";
import {useIGDialog} from "../hooks/IGDialogHook";
import {
  setBtScoreCardConfig,
  setBtScoreCardCustomerDetails,
  setBtScoreCardDetails,
  toggleModals,
} from "./activeLoans.reducer";
import {Channel} from "redux-saga";
import {countdown} from "../utils.saga";
import {RequestIdPayload} from "app/infra/services/api/serviceDesk/serviceDesk.types";
import {
  setActiveLoansLoading,
  setActiveLoansError,
} from "./activeLoans.reducer";

const {
  rejectCrSuccess,
  rejectCrFailure,
  getRejectCrConfigSuccess,
  getRejectCrConfigFailure,
  sendBackCrSuccess,
  sendBackCrFailure,
  getSendBackCrConfigSuccess,
  getSendBackCrConfigFailure,
  getCitiesListSuccess,
  getCitiesListFailure,
  getCrListSuccess,
  getCrListFailure,
  assignCrToMeSuccess,
  assignCrToMeFailure,
  removeCreditManagerSuccess,
  removeCreditManagerFailure,
  closeCRSuccess,
  closeCRFailure,
  sendBackToSchedulingSuccess,
  sendBackToSchedulingFailure,
  getLMJobDetailsSuccess,
  getLMJobDetailsFailure,
  getLMUsersSuccess,
  getLMUsersFailure,
  assignCurrentLMSuccess,
  assignCurrentLMFailure,
  removeCurrentLMSuccess,
  removeCurrentLMFailure,
  closeLMJobSuccess,
  closeLMJobFailure,
  blackListLoanSuccess,
  blackListLoanFailure,
  updateLoanAmountSuccess,
  updateLoanAmountFailure,
  getLoanTopUpAmountSuccess,
  getLoanTopUpAmountFailure,
  getMakerFieldsFlagSuccess,
  getMakerFieldsFlagFailure,
  updateLoanRenewalDetailsFailure,
  updateLoanRenewalDetailsSuccess,
  getLoanRenewalDetailsSuccess,
  getLoanRenewalDetailsFailure,
  getLoanTopUpAmount,
  getLoanRenewalDetails,
  skipPersonalLoanSuccess,
  skipPersonalLoanFailure,
  getLatestStatusSuccess,
  getLatestStatusFailure,
  getLMInsuranceDetailsSuccess,
  getLMInsuranceDetailsFailure,
  getInsuranceFeatureFlagSuccess,
  getInsuranceFeatureFlagFailure,
  skipOfficeReviewSuccess,
  skipOfficeReviewFailure,
  getLandDocumentDetailSuccess,
  getLandDocumentDetailFailure,
  deleteLandDocumentDetailSuccess,
  deleteLandDocumentDetailFailure,
  getMatchingLandDocumentDetailFailure,
  getMatchingLandDocumentDetailSuccess,
  getLandDocumentDetail,
  postLandDocumentDetailFailure,
  postLandDocumentDetailSuccess,
  markLandDocumentSuccess,
  markLandDocumentFailure,
  getLandStateListSuccess,
  getLandStateListFailure,
  getLandOwnerBorrowerListSuccess,
  getLandOwnerBorrowerListFailure,
  markLandDocumentAsSameSuccess,
  markLandDocumentAsSameFailure,
  getAgriExposureAmountSuccess,
  getAgriExposureAmountFailure,
  getAgriExposureAmount,
  getMatchingLandDocumentDetail,
  activateLoanMakerSuccess,
  activateLoanMakerFailure,
  getPrefilledLoanMakerDetailsSuccess,
  getPrefilledLoanMakerDetailsFailure,
  getPostLoanDocsFlagSuccess,
  getPostLoanDocsFlagFailure,
  getLoanRequirementReasonSuccess,
  getLoanRequirementReasonFailure,
  getActiveLoansTabsListSuccess,
  getActiveLoansTabsListFailure,
  getLoanCheckerStatusSuccess,
  getLoanCheckerStatusFailure,
  getLoanCheckerStatus,
} = activeLoansActions;

function* getRejectCrConfigFlow() {
  const api: GoldApi = yield getContext("api");

  const {error, response} = yield call(api.activeLoans.getRejectCrConfig);

  if (error) {
    yield put(getRejectCrConfigFailure({error, response: null}));
  } else {
    yield put(getRejectCrConfigSuccess({error, response}));
  }
}

function* rejectCrFlow(action: PayloadAction<RejectCrPayload>) {
  const api: GoldApi = yield getContext("api");

  const {error, response} = yield call(
    api.activeLoans.rejectCr,
    action.payload,
  );

  if (error) {
    yield put(rejectCrFailure({error, response: null}));
    yield put(toggleModals({type: "rejectCr", value: false}));
  } else {
    yield put(rejectCrSuccess({error, response}));
    yield put(
      snackBarActions.open({
        message: "Successfully Rejected CR",
        variant: "success",
      }),
    );
    yield put(toggleModals({type: "rejectCr", value: false}));
  }
}

function* getSendBackCrConfigFlow() {
  const api: GoldApi = yield getContext("api");

  const {error, response} = yield call(api.activeLoans.getSendBackCrConfig);
  if (error) {
    yield put(getSendBackCrConfigFailure({error, response: null}));
  } else {
    yield put(getSendBackCrConfigSuccess({error, response}));
  }
}

function* sendBackCrFlow(action: PayloadAction<SendBackCrPayload>) {
  const api: GoldApi = yield getContext("api");

  const {error, response} = yield call(
    api.activeLoans.sendBackCr,
    action.payload,
  );

  if (error) {
    yield put(sendBackCrFailure({error, response: null}));
    yield put(toggleModals({type: "askLmForAdditionalDetail", value: false}));
  } else {
    yield put(sendBackCrSuccess({error, response}));
    yield put(
      snackBarActions.open({
        message: "Successfully Sent back CR",
        variant: "success",
      }),
    );
    yield put(toggleModals({type: "askLmForAdditionalDetail", value: false}));
  }
}

function* getCitiesListFlow() {
  const api: GoldApi = yield getContext("api");

  const {cities, error} = yield call(api.activeLoans.getCitiesList);
  if (error) {
    yield put(getCitiesListFailure({error, cities: []}));
  } else {
    yield put(getCitiesListSuccess({error: null, cities}));
  }
}

function* getCrListFlow(action: PayloadAction<GetActiveLoansCRListPayload>) {
  const api: GoldApi = yield getContext("api");

  const {error, crList} = yield call(
    api.activeLoans.getCrList,
    action.payload,
  );

  if (error) {
    yield put(getCrListFailure({error, crList: null}));
  } else {
    yield put(getCrListSuccess({error, crList}));
  }
}

function* assignCrToMe(action: PayloadAction<AssignCRToMePayload>) {
  const api: GoldApi = yield getContext("api");

  const {error, response} = yield call(
    api.activeLoans.assignCrToMe,
    action.payload,
  );

  if (error) {
    yield put(assignCrToMeFailure({error, response: null}));
  } else {
    yield put(
      assignCrToMeSuccess({
        error: null,
        response: {...response, ...action.payload},
      }),
    );
  }
}

function* removeCreditManagerFlow(action: PayloadAction<number>) {
  const api: GoldApi = yield getContext("api");

  const {error, response} = yield call(
    api.activeLoans.removeCreditManager,
    action.payload,
  );
  if (error) {
    yield put(removeCreditManagerFailure({error, response: null}));
  } else {
    yield put(
      removeCreditManagerSuccess({
        error: null,
        response: response,
        crId: action.payload,
      }),
    );
  }
}

function* closeCRFlow(action: PayloadAction<number>) {
  const api: GoldApi = yield getContext("api");

  const {error, response} = yield call(
    api.activeLoans.closeCustomerRequest,
    action.payload,
  );
  if (error) {
    yield put(closeCRFailure({error, response: null}));
  } else {
    yield put(
      closeCRSuccess({
        error: null,
        response: {...response, crId: action.payload},
      }),
    );
  }
}

function* sendBackToSchedulingFlow(
  action: PayloadAction<SendBackToSchedulingPayload>,
) {
  const api: GoldApi = yield getContext("api");

  const {error, response} = yield call(
    api.activeLoans.sendBackToScheduling,
    action.payload,
  );
  if (error) {
    yield put(sendBackToSchedulingFailure({error, response: null}));
  } else {
    yield put(
      sendBackToSchedulingSuccess({
        error: null,
        response,
        crId: action.payload.crId,
      }),
    );
  }
}

function* getLMJobDetailsFlow(action: PayloadAction<number>) {
  const api: GoldApi = yield getContext("api");

  const {error, response} = yield call(
    api.activeLoans.getLMJobDetails,
    action.payload,
  );
  if (error) {
    yield put(getLMJobDetailsFailure({error, response: null}));
  } else {
    yield put(getLMJobDetailsSuccess({error: null, response}));
  }
}

function* getLMUserListFlow(action: PayloadAction<GetLMUsersListPayload>) {
  const api: GoldApi = yield getContext("api");

  const {error, lmUserList} = yield call(
    api.activeLoans.getLMUsers,
    action.payload,
  );
  if (error) {
    yield put(getLMUsersFailure({error, lmUserList: []}));
  } else {
    yield put(getLMUsersSuccess({error: null, lmUserList}));
  }
}

function* assignCurrentLMFlow(
  action: PayloadAction<AssignOrRemoveCurrentLMPayload>,
) {
  const api: GoldApi = yield getContext("api");

  const {error, response} = yield call(
    api.activeLoans.assignCurrentLM,
    action.payload,
  );

  if (error) {
    yield put(assignCurrentLMFailure({error, response: null}));
  } else {
    yield put(
      assignCurrentLMSuccess({
        error: null,
        response: {...response, ...action.payload},
      }),
    );
  }
}

function* removeCurrentLMFlow(
  action: PayloadAction<AssignOrRemoveCurrentLMPayload>,
) {
  const api: GoldApi = yield getContext("api");

  const {error, response} = yield call(
    api.activeLoans.removeCurrentLM,
    action.payload,
  );

  if (error) {
    yield put(removeCurrentLMFailure({error, response: null}));
  } else {
    yield put(
      removeCurrentLMSuccess({
        error: null,
        response: {...response, ...action.payload},
      }),
    );
  }
}

function* closeLMJobFlow(action: PayloadAction<CloseCurrentLMPayload>) {
  const api: GoldApi = yield getContext("api");

  const {error, response} = yield call(
    api.activeLoans.closeLMJob,
    action.payload,
  );

  if (error) {
    yield put(closeLMJobFailure({error, response: null}));
  } else {
    yield put(
      closeLMJobSuccess({
        error: null,
        response: {...response, ...action.payload},
      }),
    );
  }
}

function* blackListLoanFlow(action: PayloadAction<BlackListLoanPayload[]>) {
  const api: GoldApi = yield getContext("api");

  const {error, response} = yield call(
    api.activeLoans.blacklistLoan,
    action.payload,
  );

  if (error) {
    yield put(blackListLoanFailure({error, response: null}));
  } else {
    yield put(blackListLoanSuccess({error: null, response}));
  }
}

function* getLatestStatusFlow(action: PayloadAction<LatestStatusPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.getLatestJobStatus,
    action.payload,
  );
  if (error) {
    yield put(getLatestStatusFailure({response: null, error}));
  } else {
    yield put(getLatestStatusSuccess({response, error: null}));
  }
}

function* getActiveLoansTabsListFlow() {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.getActiveLoansTabsList,
  );
  if (error) {
    yield put(getActiveLoansTabsListFailure({response: null, error}));
  } else {
    yield put(getActiveLoansTabsListSuccess({response, error: null}));
  }
}

function* generalErrorFlow(action: PayloadAction<ActiveLoansResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message || "Something went wrong!!",
      variant: "error",
    }),
  );
}

function* generalSuccessFlow() {
  yield put(
    snackBarActions.open({
      message: "Successfully done",
      variant: "success",
    }),
  );
}

function* assignCurrentLMSuccessFlow() {
  yield put(
    snackBarActions.open({
      message: "Successfully Assign LM To a Job",
      variant: "success",
    }),
  );
}

function* sendBackToSchedulingSuccessFlow() {
  yield put(
    snackBarActions.open({
      message: "Successfully Sent back to Scheduling",
      variant: "success",
    }),
  );
}

function* updateLoanAmountFlow(
  action: PayloadAction<LoanAmountUpdateRequestPayload & RequestIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.updateLoanAmount,
    action.payload,
  );
  if (error) {
    yield put(updateLoanAmountFailure({response: null, error}));
  } else {
    yield put(updateLoanAmountSuccess({response, error: null}));
    console.log("Request ID ---> ", action.payload.requestId);
    yield put(
      getLoanRenewalDetails({
        crId: action.payload.crId,
        requestId: action.payload.requestId,
      }),
    );
    // yield put(
    //   getLoanTopUpAmount({
    //     crId: action.payload.crId,
    //   }),
    // );
    yield put(
      toggleModals({
        type: "newLoanAmountUpdationModal",
        value: false,
      }),
    );
  }
}

function* getLoanTopUpAmountFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.getLoanTopUpAmount,
    action.payload,
  );
  if (error) {
    yield put(getLoanTopUpAmountFailure({response: null, error}));
  } else {
    yield put(
      getLoanTopUpAmountSuccess({
        response,
        error: null,
        crId: action.payload.crId,
      }),
    );
  }
}

function* getLoanMakerFieldsFlagFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.getLoanMakerFieldsFlag,
    action.payload,
  );
  if (error) {
    yield put(getMakerFieldsFlagFailure({response: null, error}));
  } else {
    yield put(
      getMakerFieldsFlagSuccess({
        response,
        error: null,
      }),
    );
  }
}
function* getLoanRenewalDetailsFlow(
  action: PayloadAction<CrIdPayload & RequestIdPayload>,
) {
  const api: GoldApi = yield getContext("api");

  if (!action.payload.requestId) {
    const {response, error} = yield call(
      api.activeLoans.getLoanRenewalDetails,
      action.payload,
    );
    if (error) {
      yield put(getLoanRenewalDetailsFailure({response: null, error}));
    } else {
      yield put(
        getLoanRenewalDetailsSuccess({
          response,
          error: null,
          crId: action.payload.crId,
        }),
      );
    }
  } else {
    const chan: Channel<number> = yield call(countdown, {
      seconds: 61,
      intervalTime: 1000,
    });

    try {
      while (true) {
        const seconds: number = yield take(chan);
        console.log("Seconds ---> ", seconds);
        if (seconds % 4 === 0) {
          console.log("Fetching Offer Expiry Status");
          const {response, error} = yield call(
            api.renewRebook.getOfferExpiryStatus,
            {
              requestId: action.payload.requestId,
              offerBy: "CM",
            },
          );

          if (error) {
            yield put(
              snackBarActions.open({message: error.message, variant: "error"}),
            );
          }

          if (response === false) {
            localStorage.setItem("IS_OFFER_EXPIRED", "false");
            chan.close();
          }
        }
      }
    } finally {
      if (localStorage.getItem("IS_OFFER_EXPIRED") === "false") {
        const {response, error} = yield call(
          api.activeLoans.getLoanRenewalDetails,
          action.payload,
        );

        if (error) {
          yield put(getLoanRenewalDetailsFailure({response, error}));
        } else {
          yield put(
            getLoanRenewalDetailsSuccess({
              response,
              error: null,
              crId: action.payload.crId,
            }),
          );
        }
      } else {
        yield put(
          getLoanRenewalDetailsFailure({
            response: null,
            error: {
              message: "Offer has been expired. Please reload the window.",
              errors: [],
              code: "408",
              developerCode: "408",
              fieldError: "",
            },
          }),
        );
        yield put(
          snackBarActions.open({
            message: "Offer has been expired. Please reload the window.",
            variant: "error",
          }),
        );
      }
      localStorage.removeItem("IS_OFFER_EXPIRED");
      console.log("countdown terminated");
    }
  }
}

function* updateLoanRenewalDetailsFlow(
  action: PayloadAction<LoanRenewalAmountDetailsPayload & RequestIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.postLoanRenewalDetails,
    action.payload,
  );
  if (error) {
    yield put(updateLoanRenewalDetailsFailure({response: null, error}));
  } else {
    yield put(updateLoanRenewalDetailsSuccess({response, error: null}));
    yield put(
      getLoanTopUpAmount({
        crId: action.payload.crId,
      }),
    );
    yield put(
      getLoanRenewalDetails({
        crId: action.payload.crId,
        requestId: action.payload.requestId,
      }),
    );
  }
}

function* skipPersonalLoanFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.skipPersonalLoan,
    action.payload,
  );
  if (error) {
    yield put(skipPersonalLoanFailure({response: null, error}));
  } else {
    yield put(skipPersonalLoanSuccess({response, error: null}));
  }
}

function* getInsuranceFeatureFlagFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.getInsuranceFeatureFlag,
    action.payload,
  );
  if (error) {
    yield put(getInsuranceFeatureFlagFailure({response: null, error}));
  } else {
    yield put(getInsuranceFeatureFlagSuccess({response, error: null}));
  }
}

function* getLMInsuranceDetailsFlow(
  action: PayloadAction<LMInsuranceDetailsPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.getLMInsuranceDetails,
    action.payload,
  );
  if (error) {
    yield put(getLMInsuranceDetailsFailure({response: null, error}));
  } else {
    yield put(getLMInsuranceDetailsSuccess({response, error: null}));
  }
}

function* skipOfficeReviewFlow(action: PayloadAction<SkipOfficeReviewPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.skipOfficeReview,
    action.payload,
  );
  if (error) {
    yield put(skipOfficeReviewFailure({response: null, error}));
  } else {
    yield put(skipOfficeReviewSuccess({response, error: null}));
    yield put(
      snackBarActions.open({
        message: "Review has been successfully skipped!",
        variant: "success",
      }),
    );
  }
}

function* getLandDocumentDetailFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.getLandDocumentDetail,
    action.payload,
  );
  if (error) {
    yield put(getLandDocumentDetailFailure({response: null, error}));
  } else {
    yield put(getLandDocumentDetailSuccess({response, error: null}));
  }
}

function* deleteLandDocumentDetailFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.deleteLandDocumentDetail,
    action.payload,
  );
  if (error) {
    yield put(deleteLandDocumentDetailFailure({response: null, error}));
  } else {
    yield put(deleteLandDocumentDetailSuccess({response, error: null}));
    yield put(
      snackBarActions.open({
        message: "Document has been successfully deleted!",
        variant: "success",
      }),
    );

    yield put(
      getLandDocumentDetail({
        crId: action.payload.crId,
      }),
    );
  }
}

function* getMatchingLandDocumentDetailFlow(
  action: PayloadAction<CrIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.getMatchingLandDocumentDetail,
    action.payload,
  );
  if (error) {
    yield put(getMatchingLandDocumentDetailFailure({response: null, error}));
  } else {
    yield put(getMatchingLandDocumentDetailSuccess({response, error: null}));
  }
}

function* getAgriExposureAmountFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.getAgriExposureAmount,
    action.payload,
  );
  if (error) {
    yield put(getAgriExposureAmountFailure({response: null, error}));
  } else {
    yield put(getAgriExposureAmountSuccess({response, error: null}));
  }
}

function* getLandStateListFlow() {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.activeLoans.getLandStateList);
  if (error) {
    yield put(getLandStateListFailure({response: null, error}));
  } else {
    yield put(getLandStateListSuccess({response, error: null}));
  }
}

function* getLandOwnerBorrowerListFlow() {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.getLandOwnerBorrowerList,
  );
  if (error) {
    yield put(getLandOwnerBorrowerListFailure({response: null, error}));
  } else {
    yield put(getLandOwnerBorrowerListSuccess({response, error: null}));
  }
}

function* markLandDocumentFlow(action: PayloadAction<MarkLandDocumentPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.markLandDocument,
    action.payload,
  );
  if (error) {
    yield put(markLandDocumentFailure({response: null, error}));
  } else {
    yield put(markLandDocumentSuccess({response, error: null}));
    yield put(
      snackBarActions.open({
        message: "Successfully marked for this crid!",
        variant: "success",
      }),
    );
    yield put(
      toggleModals({
        type: "isMatchingDocsPopupOpen",
        value: false,
      }),
    );
    yield put(
      getLandDocumentDetail({
        crId: action.payload.crId,
      }),
    );
  }
}

function* activateLoanMakerFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.activateLoanMaker,
    action.payload,
  );
  if (error) {
    yield put(activateLoanMakerFailure({response: null, error}));
  } else {
    yield put(activateLoanMakerSuccess({response, error: null}));
    yield put(
      snackBarActions.open({
        message: "Loan maker flow has been activated!",
        variant: "success",
      }),
    );
  }
}

function* getLoanMakerPrefilledDetailsFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.getPrefilledLoanMakerDetails,
    action.payload,
  );
  if (error) {
    yield put(getPrefilledLoanMakerDetailsFailure({response: null, error}));
  } else {
    yield put(getPrefilledLoanMakerDetailsSuccess({response, error: null}));
  }
}

function* markLandDocumentAsSameFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.markLandDocumentAsSame,
    action.payload,
  );
  if (error) {
    yield put(markLandDocumentAsSameFailure({response: null, error}));
  } else {
    yield put(markLandDocumentAsSameSuccess({response, error: null}));
    yield put(
      snackBarActions.open({
        message: "Successfully marked current doc as same doc!",
        variant: "success",
      }),
    );
    yield put(
      toggleModals({
        type: "isMatchingDocsPopupOpen",
        value: false,
      }),
    );
    yield put(
      getLandDocumentDetail({
        crId: action.payload.crId,
      }),
    );
  }
}

function* getPostLoanDocsFlagFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.getPostLoanDocsFlag,
    action.payload,
  );
  if (error) {
    yield put(getPostLoanDocsFlagFailure({response: null, error}));
  } else {
    yield put(getPostLoanDocsFlagSuccess({response, error: null}));
  }
}

function* getLoanRequirementReasonFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.getLoanRequirementReason,
    action.payload,
  );
  if (error) {
    yield put(getLoanRequirementReasonFailure({response: null, error}));
  } else {
    yield put(getLoanRequirementReasonSuccess({response, error: null}));
  }
}

function* getLoanCheckerStatusFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.getLoanCheckerStatus,
    action.payload,
  );
  if (error) {
    yield put(getLoanCheckerStatusFailure({response: null, error}));
  } else {
    yield put(getLoanCheckerStatusSuccess({response, error: null}));
  }
}

function* postLandDocumentDetailFlow(
  action: PayloadAction<LandDocumentDetailPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.postLandDocumentDetail,
    action.payload,
  );
  if (error) {
    yield put(postLandDocumentDetailFailure({response: null, error}));
  } else {
    yield put(postLandDocumentDetailSuccess({response, error: null}));

    yield put(
      snackBarActions.open({
        message: "Document has been successfully uploaded!",
        variant: "success",
      }),
    );
    yield put(
      getLandDocumentDetail({
        crId: action.payload.crId,
      }),
    );
  }
}

function* sendApprovalMailToLenderFlow(action: PayloadAction<CrIdPayload>) {
  yield put(
    setActiveLoansLoading({key: "sendApprovalMailToLender", value: true}),
  );
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.sendApprovalMailToLender,
    action.payload,
  );
  if (error) {
    yield put(
      setActiveLoansError({key: "sendApprovalMailToLender", value: error}),
    );
    yield put(
      snackBarActions.open({
        message: error?.message,
        variant: "error",
      }),
    );
  } else {
    yield put(
      snackBarActions.open({
        message: response?.message || "Mail has been sent!",
        variant: "success",
      }),
    );
  }
  yield put(
    setActiveLoansLoading({key: "sendApprovalMailToLender", value: false}),
  );
}

function* requestLenderApprovalFlow(action: PayloadAction<CrIdPayload>) {
  yield put(
    setActiveLoansLoading({key: "requestLenderApproval", value: true}),
  );
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.requestLenderApproval,
    action.payload,
  );
  if (error) {
    yield put(
      setActiveLoansError({key: "requestLenderApproval", value: error}),
    );
    yield put(
      snackBarActions.open({
        message: error?.message,
        variant: "error",
      }),
    );
  } else {
    yield put(
      snackBarActions.open({
        message: response?.message,
        variant: "success",
      }),
    );
    yield put(
      getLoanCheckerStatus({
        crId: action.payload.crId,
      }),
    );
  }
  yield put(
    setActiveLoansLoading({key: "requestLenderApproval", value: false}),
  );
}

function* getBtScoreCardDetailsFlow(action: PayloadAction<CrIdPayload>) {
  yield put(
    setActiveLoansLoading({key: "getBtScoreCardDetails", value: true}),
  );
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.getBtScoreCardDetails,
    action.payload,
  );
  if (error) {
    yield put(
      setActiveLoansError({key: "getBtScoreCardDetails", value: error}),
    );
    yield put(
      snackBarActions.open({
        message: error?.message,
        variant: "error",
      }),
    );
  } else {
    yield put(
      setBtScoreCardDetails({
        crId: action.payload.crId,
        btScoreCardDetails: response,
      }),
    );
  }
  yield put(
    setActiveLoansLoading({key: "getBtScoreCardDetails", value: false}),
  );
}

function* getBtScoreCardCustomerDetailsFlow(
  action: PayloadAction<CrIdPayload>,
) {
  yield put(
    setActiveLoansLoading({key: "getBtScoreCardCustomerDetails", value: true}),
  );
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.getBtScoreCardCustomerDetails,
    action.payload,
  );
  if (error) {
    yield put(
      setActiveLoansError({
        key: "getBtScoreCardCustomerDetails",
        value: error,
      }),
    );
    yield put(
      snackBarActions.open({
        message: error?.message,
        variant: "error",
      }),
    );
  } else {
    yield put(
      setBtScoreCardCustomerDetails({
        crId: action.payload.crId,
        btScoreCardCustomerDetails: response,
      }),
    );
  }
  yield put(
    setActiveLoansLoading({
      key: "getBtScoreCardCustomerDetails",
      value: false,
    }),
  );
}

function* getBtScoreCardConfigFlow(
  action: PayloadAction<GetBtScoreCardConfig>,
) {
  yield put(
    setActiveLoansLoading({key: "getBtScoreCardConfig", value: true}),
  );
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.activeLoans.getBtScoreCardConfig,
    action.payload,
  );
  if (error) {
    yield put(
      setActiveLoansError({key: "getBtScoreCardConfig", value: error}),
    );
    yield put(
      snackBarActions.open({
        message: error?.message,
        variant: "error",
      }),
    );
  } else {
    yield put(setBtScoreCardConfig({response}));
  }
  yield put(
    setActiveLoansLoading({key: "getBtScoreCardConfig", value: false}),
  );
}

// >>>>>>>> Active loans Sagas <<<<<<<<<

export default function* activeLoansSaga() {
  yield takeLatest(activeLoansActionTypes.RejectCr, rejectCrFlow);
  yield takeLatest(
    activeLoansActionTypes.GetRejectCrConfig,
    getRejectCrConfigFlow,
  );
  yield takeLatest(activeLoansActionTypes.SendBackCr, sendBackCrFlow);
  yield takeLatest(
    activeLoansActionTypes.GetSendBackCrConfig,
    getSendBackCrConfigFlow,
  );
  yield takeLatest(activeLoansActionTypes.GetCitiesList, getCitiesListFlow);
  yield takeEvery(activeLoansActionTypes.GetCrList, getCrListFlow);
  yield takeLatest(activeLoansActionTypes.GetCrListFailure, generalErrorFlow);
  yield takeLatest(activeLoansActionTypes.AssignCrToMe, assignCrToMe);

  yield takeLatest(
    activeLoansActionTypes.AssignCrToMeSuccess,
    generalSuccessFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.AssignCrToMeFailure,
    generalErrorFlow,
  );

  yield takeLeading(
    activeLoansActionTypes.RemoveCreditManager,
    removeCreditManagerFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.RemoveCreditManagerSuccess,
    generalSuccessFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.RemoveCreditManagerFailure,
    generalErrorFlow,
  );

  yield takeLatest(activeLoansActionTypes.CloseCR, closeCRFlow);
  yield takeLatest(activeLoansActionTypes.CloseCRFailure, generalErrorFlow);

  yield takeLatest(activeLoansActionTypes.CloseCRSuccess, generalSuccessFlow);

  yield takeLatest(
    activeLoansActionTypes.SendBackToScheduling,
    sendBackToSchedulingFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.SendBackToSchedulingFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.SendBackToSchedulingSuccess,
    sendBackToSchedulingSuccessFlow,
  );

  yield takeLatest(activeLoansActionTypes.GetLMJobDetails, getLMJobDetailsFlow);

  yield takeLatest(activeLoansActionTypes.GetLMUsers, getLMUserListFlow);

  yield takeLatest(activeLoansActionTypes.AssignCurrentLM, assignCurrentLMFlow);

  yield takeLatest(
    activeLoansActionTypes.AssignCurrentLMSuccess,
    assignCurrentLMSuccessFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.AssignCurrentLMFailure,
    generalErrorFlow,
  );

  yield takeLatest(activeLoansActionTypes.RemoveCurrentLM, removeCurrentLMFlow);

  yield takeLatest(
    activeLoansActionTypes.RemoveCurrentLMSuccess,
    generalSuccessFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.RemoveCurrentLMFailure,
    generalErrorFlow,
  );
  yield takeLatest(activeLoansActionTypes.CloseLMJob, closeLMJobFlow);

  yield takeLatest(
    activeLoansActionTypes.CloseLMJobSuccess,
    generalSuccessFlow,
  );

  yield takeLatest(activeLoansActionTypes.GetLatestStatus, getLatestStatusFlow);
  yield takeLatest(
    activeLoansActionTypes.GetLatestStatusFailure,
    generalErrorFlow,
  );

  yield takeLatest(activeLoansActionTypes.CloseLMJobFailure, generalErrorFlow);

  yield takeLatest(activeLoansActionTypes.BlackListLoan, blackListLoanFlow);
  yield takeLatest(activeLoansActionTypes.BlackListLoanSuccess, function*() {
    yield useIGDialog().showSuccessDialog({
      message: "Customer identifier details updated successfully!",
    });
  });
  yield takeLatest(
    activeLoansActionTypes.BlackListLoanFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.UpdateLoanAmount,
    updateLoanAmountFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.GetLoanTopUpAmount,
    getLoanTopUpAmountFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.GetMakerFieldsFlag,
    getLoanMakerFieldsFlagFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.GetLoanRenewalDetails,
    getLoanRenewalDetailsFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.UpdateLoanRenewalDetails,
    updateLoanRenewalDetailsFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.UpdateLoanRenewalDetailsFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.GetLoanRenewalDetailsFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.UpdateLoanAmountFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.SkipPersonalLoanFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.GetLoanTopUpAmountFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.GetMakerFieldsFlagFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.UpdateLoanAmountSuccess,
    generalSuccessFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.SkipPersonalLoan,
    skipPersonalLoanFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.GetLmInsuranceDetails,
    getLMInsuranceDetailsFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.GetLmInsuranceDetailsFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.GetInsuranceFeatureFlag,
    getInsuranceFeatureFlagFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.GetInsuranceFeatureFlagFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.SkipOfficeReview,
    skipOfficeReviewFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.SkipOfficeReviewFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.GetLandDocumentDetail,
    getLandDocumentDetailFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.GetLandDocumentDetailFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.DeleteLandDocumentDetail,
    deleteLandDocumentDetailFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.DeleteLandDocumentDetailFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.GetMatchingLandDocumentDetail,
    getMatchingLandDocumentDetailFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.GetMatchingLandDocumentDetailFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.PostLandDocumentDetail,
    postLandDocumentDetailFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.PostLandDocumentDetailFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.MarkLandDocument,
    markLandDocumentFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.MarkLandDocumentFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.GetLandStateList,
    getLandStateListFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.GetLandStateListFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.GetLandOwnerBorrowerList,
    getLandOwnerBorrowerListFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.GetLandOwnerBorrowerListFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.MarkLandDocumentAsSame,
    markLandDocumentAsSameFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.MarkLandDocumentAsSameFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.GetAgriExposureAmount,
    getAgriExposureAmountFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.GetAgriExposureAmountFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.ActivateLoanMaker,
    activateLoanMakerFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.ActivateLoanMakerFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.GetPrefilledLoanMakerDetails,
    getLoanMakerPrefilledDetailsFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.GetPrefilledLoanMakerDetailsFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.GetPostLoanDocsFlag,
    getPostLoanDocsFlagFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.GetPostLoanDocsFlagFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.GetLoanRequirementReason,
    getLoanRequirementReasonFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.GetLoanRequirementReasonFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.GetActiveLoansTabsList,
    getActiveLoansTabsListFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.GetActiveLoansTabsListFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.GetLoanCheckerStatus,
    getLoanCheckerStatusFlow,
  );
  yield takeLatest(
    activeLoansActionTypes.GetLoanCheckerStatusFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.SendApprovalMailToLender,
    sendApprovalMailToLenderFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.RequestLenderApproval,
    requestLenderApprovalFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.GetBtScoreCardDetails,
    getBtScoreCardDetailsFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.GetBtScoreCardConfig,
    getBtScoreCardConfigFlow,
  );

  yield takeLatest(
    activeLoansActionTypes.GetBtScoreCardCustomerDetails,
    getBtScoreCardCustomerDetailsFlow,
  );
}
