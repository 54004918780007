import React, {useState, useCallback, useEffect, useRef} from "react";

import ApproveApplication from "../CommonComponents/Modal/ApproveApplication";
import RejectApplication from "../CommonComponents/Modal/ReasonToReject";
import AskLmForAdditionalDetail from "../CommonComponents/Modal/AskLmForAdditionalDetail";
import getNextJob from "api/business/getNextJob";
import {IGButton} from "app/components";
import {Stack} from "@mui/material";
import {activeLoansSelectors} from "app/store/activeLoans";
import {useAppSelector, useAppDispatch} from "app/store/hooks";
import {toggleModals} from "app/store/activeLoans/activeLoans.reducer";
import {CrRejectionSourceEnum} from "app/enums/gold_loan/activeLoans";

const ApproveButton = ({
  selectedPayload,
  otherRequestTimer,
  getMileStoneOfCr,
  jobType = "CM_TAKEOVER_KYC_APPROVAL",
  takeoverCustomerAge,
  showTakeoverKycAlert,
  isAllChecked,
}) => {
  const dispatch = useAppDispatch();
  const openModals = useAppSelector(activeLoansSelectors.getOpenModals);
  const isBtScoreCardMilestoneCheckedByCm = useAppSelector(
    activeLoansSelectors.getBtScoreCardMilestoneCheckedByCm,
  );
  const [approveApplication, setApproveApplication] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [nextJobStates, setNextJobStates] = useState({
    loading: false,
    error: false,
    payload: null,
    errorMessage: "",
  });
  const counter = useRef(0);

  const getNextJobApi = useCallback(async () => {
    setButtonLoading(true);
    try {
      setNextJobStates({
        loading: true,
        error: false,
        payload: null,
      });
      const response = await getNextJob({
        accessToken: localStorage.getItem("authToken"),
        crId: selectedPayload.id,
        jobType,
      });

      if (response.statusCode === 200) {
        setNextJobStates({
          loading: false,
          error: false,
          payload: response.payload,
          errorMessage: "",
        });
      } else {
        setNextJobStates({
          loading: false,
          error: true,
          payload: null,
          errorMessage: response.error,
        });
      }
    } catch (error) {
      setNextJobStates({
        loading: false,
        error: true,
        payload: null,
        errorMessage: "Something went wrong",
      });
    }
    setButtonLoading(false);
  }, [selectedPayload.id, jobType]);

  useEffect(() => {
    (async () => {
      if (counter.current === 0) {
        counter.current = 1;
        getNextJobApi();
      }
    })();
  }, [
    getNextJobApi,
    selectedPayload.id,
    selectedPayload.milestone,
    selectedPayload.type,
  ]);

  const onClickApproveHandler = useCallback(() => {
    setApproveApplication(true);
  }, []);

  const onClickRejectHandler = () => {
    dispatch(
      toggleModals({
        type: "rejectCr",
        value: true,
      }),
    );
  };

  const onClickSendMessageHandler = () => {
    dispatch(
      toggleModals({
        type: "askLmForAdditionalDetail",
        value: true,
      }),
    );
  };

  const crJobId = nextJobStates.payload?.id;

  useEffect(() => {
    const timer = setInterval(() => {
      getNextJobApi();
    }, otherRequestTimer);

    return () => {
      clearInterval(timer);
    };
  }, [getNextJobApi, otherRequestTimer]);

  return (
    <>
      {openModals.rejectCr && (
        <RejectApplication
          crId={selectedPayload.id}
          crJobId={crJobId}
          open={openModals.rejectCr}
          rejectionSource={CrRejectionSourceEnum.TAKEOVER_KYC}
          getMileStoneOfCr={getMileStoneOfCr}
          getNextJobApi={getNextJobApi}
          crJobStatus="FAILED"
          title="REJECT KYC"
          confirmText="REJECT KYC"
        />
      )}

      {openModals.askLmForAdditionalDetail && (
        <AskLmForAdditionalDetail
          crId={selectedPayload.id}
          crJobId={crJobId}
          open={openModals.askLmForAdditionalDetail}
          sendBackSource={CrRejectionSourceEnum.TAKEOVER_KYC}
          getMileStoneOfCr={getMileStoneOfCr}
          getNextJobApi={getNextJobApi}
          crJobStatus="FAILED"
        />
      )}

      <ApproveApplication
        crJobId={crJobId}
        selectedPayload={selectedPayload}
        approveApplication={approveApplication}
        setApproveApplication={setApproveApplication}
        getMileStoneOfCr={getMileStoneOfCr}
        getNextJobApi={getNextJobApi}
        takeoverCustomerAge={takeoverCustomerAge}
        jobType={jobType}
      />

      {crJobId && (
        <>
          <Stack direction="row" spacing={1}>
            <IGButton
              onClick={onClickApproveHandler}
              loading={buttonLoading}
              color="golden"
              size="large"
              disabled={
                showTakeoverKycAlert ||
                (jobType === "CM_TAKEOVER_KYC_APPROVAL" &&
                  !(isAllChecked && isBtScoreCardMilestoneCheckedByCm))
              }
            >
              APPROVE KYC
            </IGButton>
            <IGButton
              onClick={onClickSendMessageHandler}
              loading={buttonLoading}
              color="inherit"
              size="large"
            >
              ASK LM FOR ADDITIONAL DETAILS
            </IGButton>
            <IGButton
              variant="text"
              sx={{
                textDecoration: "underline",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                  textDecoration: "underline",
                },
              }}
              onClick={onClickRejectHandler}
              loading={buttonLoading}
              color="inherit"
              size="large"
            >
              REJECT KYC
            </IGButton>
          </Stack>
        </>
      )}
    </>
  );
};

export default ApproveButton;
